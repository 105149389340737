<template>
  <div class="employees">
    <HeadersPages
      button="+ اضافة المكتبات الالكترونية"
      link="/add-electroniclibrary"
      title="المكتبات الالكترونية"
      icon="fa-solid fa-globe"
      v-if="create"
    />
    <div class="alll">
      <TablesPageVue
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        :headers="tableHeaders"
        :ismaster="ismaster"
        :pages="tablePages"
        :rows="tableRows"
        :showSelect="false"
        @delete="deletelibrary"
      />
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useLibraryStore } from "@/stores/librarires/libraryStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "الملف", "الاسم"],
      editLink: "/edit-electroniclibrary",
      viewLink: "/view-electroniclibrary",
    };
  },
  computed: {
    ...mapState(useLibraryStore, {
      library: (state) => state.library,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.library, "ssss");
      return this.library.map((emp) => [
        emp.id,
        {
          to: emp.file,
          icon: "fa-solid fa-file-pdf",
        },
        emp.name,
        // emp.email,
        // emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const libraryStore = useLibraryStore();
      libraryStore.fetchlibrary(page);
    },
    async deletelibrary(id) {
      const libraryStore = useLibraryStore();
      console.log(id);

      await libraryStore.deletelibrary(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const libraryStore = useLibraryStore();
    await libraryStore.fetchlibrary();
  },
};
</script>
