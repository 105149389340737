import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useCoursesStore = defineStore("courses", {
  state: () => ({
    courses: [],
  }),
  actions: {
    async fetchCourses(page = 1, word = "") {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      try {
        const response = await axios.post(`fetch_courses?page=${page}`, {
          word: word,
        });

        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;
        if (response.data.status) {
          this.courses = response.data.data.data;
          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        }
      } catch (error) {
        // console.error("Error fetching courses:", error);
      } finally {
        loadingStore.stopLoading();
      }
    },
    async deleteCourse(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_course", { id });
          this.courses = this.courses.filter((course) => course.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الدوره", "success");
        }
      } catch (error) {
        console.error("Error deleting course:", error);
        Swal.fire("", error.response.data.message, "error");
      }
    },
    filterCourses(word) {
      if (word === "") {
        return this.courses; // Return all Curriculas if no search word
      } else {
        return this.courses.filter(
          (dis) =>
            dis.name.toLowerCase().includes(word.toLowerCase()) || // Search by name
            dis.email.toLowerCase().includes(word.toLowerCase()) || // Search by email
            dis.phone.includes(word) // Search by phone number
        );
      }
    },
  },
});
