<template>
  <div class="employees">
    <HeadersPages
      title="أولياء الأمور"
      button="+ اضافة ولي الامر"
      link="/add-parent"
      icon="fa-solid fa-person-cane"
      v-if="create"
    />
    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن ولي الامر..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>

      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        @delete="deleteParents"
        :ismaster="ismaster"
        :deletes="deletes"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useParentsStore } from "@/stores/parent/parentindex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: [
        "ID",
        "الصور",
        "اسم الولي الامر",
        "يالبريد الالكتروني",
        "رقم الهاتف",
      ],
      editLink: "/edit-parent",
      viewLink: "/view-parent",
    };
  },
  computed: {
    ...mapState(useParentsStore, {
      Parents: (state) => state.Parents,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.parents, "ssss");
      const dataToDisplay = this.Parents;

      return dataToDisplay.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const fetchParents = useParentsStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await fetchParents.fetchParents(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await fetchParents.fetchParents(1, this.word);

      if (fetchParents.Parents.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },

    handlePageChange(page) {
      const parentsStore = useParentsStore();
      parentsStore.fetchParents(page);
    },
    async deleteParents(id) {
      const parentsStore = useParentsStore();
      console.log(id);

      await parentsStore.deleteParents(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const parentsStore = useParentsStore();
    await parentsStore.fetchParents();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped></style>
