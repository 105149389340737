<template>
  <form class="Add_form px-4" @submit.prevent="sendRole">
    <div class="Add-courses">
      <div class="headers-add">
        <i class="fa-solid fa-plus add"></i>
        <header-pages title="اضافة صلاحية" :showButton="false" class="mb-4" />
      </div>
      <div class="all-permisson">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="input">
              <label style="font-size: 14px" for="">*اسم الصلاحية</label>
              <input
                style="margin-top: -9px"
                type="text"
                v-model="roleName"
                class="form-control"
                placeholder="اكتب هنا"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr v-for="module in modules" :key="module.id">
            <td>{{ module.name }}</td>
            <td v-for="map in module.maps" :key="map.id">
              <div>
                <input
                  class="input"
                  type="checkbox"
                  :value="map.id"
                  :id="map.id + ' ' + module.id"
                  @change="
                    onCheckChange($event, map.id, module.id, module?.name)
                  "
                  :checked="isChecked(map.id, module.id)"
                />
                <label class="flabel mx-2" :for="map.id + ' ' + module.id">
                  {{ map?.name }}
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="Add">
        <button class="btn">اضافه</button>
      </div>
    </div>
  </form>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data: () => ({
    modules: [],
    modules_ids: [],
    maps_id: [],
    roleName: "",
    modules_name: [],
  }),
  methods: {
    isChecked(mapId, moduleId) {
      return this.maps_id.some(
        (mId, index) => mId === mapId && this.modules_ids[index] === moduleId
      );
    },
    getmodules() {
      axios.post("/fetch_all_modules").then((res) => {
        this.modules = res.data.data;
      });
    },

    onCheckChange(event, map_id, module_id, modules_name) {
      if (event.target.checked) {
        this.maps_id.push(map_id);
        this.modules_ids.push(module_id);
        this.modules_name.push(modules_name);
      } else {
        const index = this.maps_id.indexOf(map_id);
        if (index > -1) {
          this.maps_id.splice(index, 1);
          this.modules_ids.splice(index, 1);
          this.modules_name.splice(index, 1);
        }
      }
    },

    getRolesData() {
      axios
        .post("fetch_role_details", { id: this.$route.params.id })
        .then((res) => {
          this.roleName = res.data.data.name;
          const roleModules = res.data.data.modules;
          this.maps_id = [];
          this.modules_ids = [];
          this.modules_name = [];
          this.modules.forEach((module) => {
            const roleModule = roleModules.find((rm) => rm.id === module.id);
            if (roleModule) {
              module.maps.forEach((map) => {
                if (roleModule.maps.some((rm) => rm.id === map.id)) {
                  this.maps_id.push(map.id);
                  this.modules_ids.push(module.id);
                  this.modules_name.push(module.name);
                }
              });
            }
          });
        });
    },
    sendRole() {
      // تجميع البيانات المحددة مباشرة من الواجهة
      const modulesData = this.modules
        .map((module) => {
          // الحصول على العناصر المحددة لهذا الوحدة (module)
          const selectedMaps = module.maps.filter((map) => {
            const checkbox = document.getElementById(`${map.id} ${module.id}`);
            return checkbox ? checkbox.checked : false;
          });

          // إذا كانت هناك عناصر محددة، نضيفها إلى البيانات
          if (selectedMaps.length > 0) {
            return {
              id: module.id,
              name: module.name,
              maps: selectedMaps.map((map) => ({
                id: map.id,
                name: map.name,
              })),
            };
          }
          return null;
        })
        .filter((module) => module !== null); // نتخلص من الوحدات التي ليس لها عناصر محددة

      // إعداد البيانات للإرسال
      const dataToSend = {
        name: this.roleName,
        display_name: this.roleName,
        modules: modulesData,
        id: this.$route.params.id,
      };

      // إرسال البيانات
      axios
        .post(
          `${this.$route.params.id ? "update_role" : "store_role"}`,
          dataToSend
        )
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            text: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.error("Error sending data:", error);
          Swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getRolesData();
    }
    this.getmodules();
  },
  components: {
    HeaderPages,
  },
};
</script>

<style scoped>
.btn {
  background-color: var(--main);
  color: #fff;
}
</style>
