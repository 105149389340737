<template>
  <div class="employees">
    <HeadersPages
      title=" اراء العملاء"
      button="+ اضافة  اراء العملاء"
      link="/add-opinion-client"
      icon="fa-solid fa-globe"
      v-if="create"
    />

    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="deleteopinionClient"
        :ismaster="ismaster"
      />
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useOpinionClientStoreStore } from "@/stores/opinionClient/opinionClientStoreIndex";
// import { usePaginationStore } from "@/stores/pagination/PaginationStore";
// import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    // PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "الصور", "اسم الطالب", " الوصف"],
      editLink: "/edit-opinion-client",
      viewLink: "/view-opinion-client",
    };
  },
  computed: {
    ...mapState(useOpinionClientStoreStore, {
      opinionClientStore: (state) => state.opinionClientStore,
      // ...mapState(usePaginationStore, {
      //   paginationCurrent: (state) => state.current_page,
      //   paginationFrom: (state) => state.from,
      //   paginationLast: (state) => state.last_page,
      //   paginationPer: (state) => state.per_page,
      //   paginationTo: (state) => state.to,
      //   paginationTotal: (state) => state.total,
      // }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.opinionClientStore, "ssss");
      return this.opinionClientStore.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.description,
      ]);
    },
    // tablePages() {
    //   return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    // },
  },
  methods: {
    // handlePageChange(page) {
    //   const opinionClientStoreStore = useOpinionClientStoreStore();
    //   opinionClientStoreStore.fetchOpinionClientStore(page);
    // },
    async deleteopinionClient(id) {
      const opinionClientStoreStore = useOpinionClientStoreStore();
      console.log(id);

      await opinionClientStoreStore.deleteOpinionClientStore(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const opinionClientStoreStore = useOpinionClientStoreStore();
    await opinionClientStoreStore.fetchOpinionClientStore();
  },
};
</script>
