<template>
  <div class="add_student">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
      <header-pages title=" تعديل  طالب  " :showButton="false" link="/groups" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!student.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
              style="margin-top: 0"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="student.imageSrc" class="avatar-preview">
              <img :src="student.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اسم الطالب </label>
          <div class="input">
            <input
              type="text"
              placeholder=" ادحل اسم الطالب"
              v-model="student.name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكتروني </label>
          <div class="input">
            <input
              type="email"
              placeholder="ادخل البريد الالكتروني"
              v-model="student.email"
            />
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              placeholder="كلمة المرور"
              v-model="student.password"
            />
          </div>
        </div> -->
        <!-- <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور</label>
          <div class="input">
            <input
              type="password"
              placeholder="تأكيد كلمة المرور"
              v-model="student.password_confirmation"
            />
          </div>
        </div> -->

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> تاريخ الميلاد </label>
            <input v-model="student.date_of_birth" type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> العمر </label>
            <input v-model="student.age" type="number" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تفاصيل العنوان </label>
          <div class="input">
            <input
              type="text"
              placeholder=" اكتب تفاصيل العنوان"
              v-model="student.address"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الهاتف </label>
          <div class="input">
            <input
              type="tel"
              @input="validateInput"
              placeholder="رقم الهاتف"
              v-model="student.phone"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">فصيلة الدم</label>
          <multiselect
            placeholder=" اختار فصيلة الدم"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="student.blood_type_id"
            :options="bloodOptions"
            track-by="id"
            label="title"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختر المجموعه</label>
          <multiselect
            placeholder="  اختار المجموعه"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="student.group_ids"
            :options="groupOptions"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updategroup"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الدوله</label>
          <multiselect
            placeholder=" اختار الدوله"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="student.country_id"
            :options="countryptions"
            track-by="id"
            label="title"
          ></multiselect>
        </div>

        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار ولي الأمر</label>
          <multiselect
           placeholder="  اختار ولي الأمر"
            v-model="student.parent_id"
            :options="ParentOptions"
            :close-on-select="false"
            track-by="id"
            label="name"
          ></multiselect>
        </div> -->

        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">صلة القرابه</label>
          <multiselect
           placeholder="  اختار صلة القرابه"
            v-model="student.relation_id"
            :options="relationshipOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
          ></multiselect>
        </div> -->

        <div class="col-lg-6 col-md-6 col-12 mt-2">
          <div class="identitynumber">
            <label for="identity_number"> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="number"
                placeholder="ادخل رقم الهويه"
                v-model="student.identity_number"
                id="identity_number"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mt-2">
          <label for="identity_type">نوع الهوية</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="student.identity_type"
                value="0"
                type="radio"
                name="identity_type"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="student.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="identity_type"
                id="flexRadioDefault6"
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 mt-2">
          <label for="gender">نوع</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="student.gender"
                value="0"
                type="radio"
                name="gender"
                id="flexRadioDefault11"
              />
              <label class="form-check-label" for="flexRadioDefault11">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="student.gender"
                class="form-check-input"
                value="1"
                type="radio"
                name="gender"
                id="flexRadioDefault10"
              />
              <label class="form-check-label" for="flexRadioDefault10">
                انثي
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useStudentsEditStore } from "@/stores/student/studentEdit";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useStudentsEditStore(),

      student: {
        parent_id: "",
        name: "",
        email: "",
        phone: "",

        identity_type: "",
        relation_id: "",
        identity_number: "",
        date_of_birth: "",

        age: "",
        gender: "",
        address: "",
        image: null,
        imageSrc: "",
        blood_type_id: null,
        group_ids: [],
        country_id: "",
        type: 0,
      },
      country_valus: null,
      group_valus: null,
      bloods_valus: null,
      groupOptions: [],
      countryptions: [],
      bloodOptions: [],
      ParentOptions: [],
      relationshipOptions: [],
    };
  },
  methods: {
    validateInput() {
      this.student.phone = this.student.phone.replace(/[^0-9]/g, "");
    },
    updateParnt() {
      if (!this.student) {
        console.error("student object is not initialized.");
        return;
      }

      this.student.parent_id = this.ParentOptions?.id || null;
    },

    updaterelationship() {
      if (!this.student) {
        console.error("student object is not initialized.");
        return;
      }

      this.student.relation_id = this.relationshipOptions?.id || null;
    },

    updateblood() {
      this.student.blood_type_id =
        this.bloods_valus && this.bloods_valus.id ? this.bloods_valus.id : null;

      console.log(this.student.relation_id);
    },

    // updategroup() {
    //   this.student.group_ids =
    //     this.group_valus && this.group_valus.id ? this.group_valus.id : null;
    //   console.log(this.student.group_ids);
    // },
    removeImage() {
      this.student.image = null;
      this.student.imageSrc = "";
      axios
        .post("delete_user_image", { id: this.$route.params.id })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.student.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.student.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async submitForm() {
      this.student.blood_type_id = this.student.blood_type_id.id;
      this.student.group_ids = this.student.group_ids.map((item) => item.id);
      this.student.country_id = this.student.country_id.id;
      await this.store.editStudents(this.student);
    },
    async fetchUserData() {
      const userDetails = await this.store.fetchUserDetails(
        this.$route.params.id
      );

      this.student = { ...userDetails };
      this.student.imageSrc = userDetails.image;
      this.student.country_id = userDetails.country;
      this.student.blood_type_id = userDetails.blood_type;
      this.student.group_ids = userDetails.groups;

      // this.student.parent_id = userDetails.user_relation.parent;
      // this.student.relation_id = userDetails.user_relation.relation;
    },
    async fetchCountries() {
      await this.store.fetchCountries();
      this.countryptions = this.store.countries;
    },
    async fetchBloods() {
      await this.store.fetchBloods();
      this.bloodOptions = this.store.bloods;
    },
    async fetchGroups() {
      await this.store.fetchGroups();
      this.groupOptions = this.store.groups;
    },
    async fetchParent() {
      await this.store.fetchParent();
      this.ParentOptions = this.store.parent;
    },
    async fetchrelationship() {
      await this.store.fetchrelationship();
      this.relationshipOptions = this.store.relationship;
    },
  },
  async mounted() {
    await this.fetchUserData();
    await this.fetchCountries();
    await this.fetchBloods();
    await this.fetchGroups();
    await this.fetchParent();
    await this.fetchrelationship();
  },
};
</script>
