<template>
  <div class="courses">
    <header-pages
      title="الهيدر"
      button="+ إضافه هيدر "
      link="/add-header"
      icon="fa-solid fa-globe"
      v-if="create"
    />

    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        @delete="deleteServices"
        :deletes="deletes"
        :ismaster="ismaster"
      />
      <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useHeaderStore } from "@/stores/Header/HeaderStore";
export default {
  components: { HeaderPages, TablesPageVue },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "صوره", "الوصف", "العنوان", "العنوان الفرعي"],
      editLink: "/edit-header",
      viewLink: "/view-header",
    };
  },
  computed: {
    ...mapState(useHeaderStore, {
      header: (state) => state.header,
    }),
    // ...mapState(usePaginationStore, {
    //   paginationCurrent: (state) => state.current_page,
    //   paginationFrom: (state) => state.from,
    //   paginationLast: (state) => state.last_page,
    //   paginationPer: (state) => state.per_page,
    //   paginationTo: (state) => state.to,
    //   paginationTotal: (state) => state.total,
    // }),
    tableRows() {
      return this.header.map((head) => [
        head.id,
        head.image,
        head.title,
        head.subtitle,
        head.description,
        //   head.description,
      ]);
    },
    // tablePages() {
    //   return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    // },
  },
  methods: {
    // handlePageChange(page) {
    //   const paginationStore = usePaginationStore();
    //   paginationStore.setCurrentPage(page);
    // },
    handleDeleteGroup(id) {
      const headerStore = useHeaderStore();
      headerStore.deleteHeader(id);
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const headerStore = useHeaderStore();
    headerStore.getHeader();
  },
};
</script>
