<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <caption class="visually-hidden">
        قائمة الواجبات والنتائج
      </caption>
      <thead>
        <tr>
          <th scope="col">
            <span class="visually-hidden">تحديد</span>
          </th>
          <th scope="col">الواجب</th>
          <th scope="col">التاريخ</th>
          <th scope="col">اجابات صحيحة</th>
          <th scope="col">اجابات خاطئة</th>
          <th scope="col">الدرجة النهائية</th>
          <th scope="col">
            <span class="visually-hidden">الإجراءات</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data.exam_results" :key="item.id">
          <td>
            <input type="checkbox" aria-label="تحديد واجب اختبار قرآن كريم" />
          </td>
          <td>{{ item.title }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.correct_answers }}</td>
          <td>{{ item.wrong_answers }}</td>
          <td>{{ item.final_grade }}</td>
          <td>
            <examsDialo :item="item" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import examsDialo from "@/components/exams/dialog.vue";

export default {
  data() {
    return {
      selectedItem: null,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    showDetails(item) {
      this.selectedItem = item;
    },
  },

  components: {
    examsDialo,
  },
};
</script>
