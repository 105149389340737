<template>
  <div class="employees">
    <HeadersPages
      title="من نحن"
      button="+ اضافة من نحن"
      link="/add-whowe"
      icon="fa-solid fa-globe"
      v-if="create"
    />

    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="handleDeleteWhowe"
        :type="type"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useWhowweStore } from "@/stores/subHeaders/WhoweStore";
import { mapState } from "pinia";

export default {
  name: "WhoweIndex",
  components: {
    HeadersPages,
    TablesPageVue,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: [
        " id",
        " الصوره",
        " الوصف",
        " 	العنوان",
        " العنوان الفراعي",
      ],
      editLink: "/edit-whowe",
      viewLink: "/view-whowe",
    };
  },
  computed: {
    ...mapState(useWhowweStore, {
      whowe: (state) => state.whowe,
      type: (state) => state.type,
    }),
    tableRows() {
      // console.log(this.teachers, "ssss");
      return this.whowe.map((who) => [
        who.id,
        who.image,
        who.description,
        who.title,
        who.subtitle,
      ]);
    },
  },
  methods: {
    async handleDeleteWhowe(id) {
      const whoweStore = useWhowweStore();
      console.log(id, "lllllllllllllllllllllllllllll");

      await whoweStore.deleteWhowe(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const whoweStore = useWhowweStore();
    await whoweStore.fetchWhowe();
  },
};
</script>
