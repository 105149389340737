<template>
  <div class="col-lg-4 col-md-12 col-12">
    <div class="crad-lesson-homeworke">
      <div class="img-lesson"></div>
      <div class="parnt-card-lesson">
        <div class="text-card-lesson">
          <p>
            {{
              item.exam_type == 1
                ? "Audio"
                : item.exam_type == 2
                ? "Multiple Choice"
                : item.exam_type == 3
                ? "Matching"
                : "Correction"
            }}
          </p>
          <h3>{{ item.title }}</h3>
        </div>
        <div class="lasts-achievement">
          <div class="las">
            <!-- <div class="lesson-last-card">
              <img src="@/assets/mida/small pic.png" alt="" />
              <h3>الشيخ:أحمد عبد الملك</h3>
            </div> -->
            <div class="lesson-last-1-card">
              <i class="fa-solid fa-table-list"></i>
              <h6 class="m-0">عدد الأسألة: {{ item.questions_count }}</h6>
            </div>
          </div>
          <div class="botton-lesson">
            <button
              type="button"
              class="btn homework"
              data-bs-toggle="modal"
              :data-bs-target="'#View-details-' + item.id"
            >
              تفاصيل الاختبار
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ExamDetails :exam-id="item.id" />
</template>

<script>
import ExamDetails from "@/components/exams/details.vue";
export default {
  components: {
    ExamDetails,
  },
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style></style>
