<template>
  <div class="jobs">
    <header-pages
      title="الوظائف"
      button="+ اضافة وظيفة جديدة"
      link="/add-jops"
      icon="fa-solid fa-rotate-left"
      v-if="create"
    />

    <div class="alll">
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        @delete="handleDeleteJops"
        :deletes="deletes"
      />

      <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useJobsStore } from "@/stores/jopsType/JopsStore";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";

export default {
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },

  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "الوظيفه"],
      editLink: "/edit-jops",
      viewLink: "/details",
    };
  },
  computed: {
    ...mapState(useJobsStore, {
      jobs: (state) => state.jobs,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      console.log(this.jobs);
      if (!Array.isArray(this.jobs)) {
        console.warn("jobs is not an array:", this.jobs);
        return [];
      }
      return this.jobs.map((jb) => [jb.id, jb.title]);
    },

    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const jopsStore = useJobsStore();
    jopsStore.getJobs();
  },

  methods: {
    handlePageChange(page) {
      const jopsStore = useJobsStore();
      jopsStore.getJobs(page);
    },
    handleDeleteJops(id) {
      const jopsStore = useJobsStore();
      jopsStore.deleteJobs(id);
    },
  },
};
</script>

<style scoped></style>
