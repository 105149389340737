<template>
  <div class="employees">
    <HeadersPages
      title=" الخدمات المميزه"
      button="+ اضافة خدمه مميزه"
      link="/add-feature-serves"
      icon="fa-solid fa-globe"
      v-if="create"
    />
    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث الخدمات..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="deleteServices"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useAllServicesStore } from "@/stores/featureServices/featureservicesStoreIndex";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { debounce } from "lodash";
export default {
  name: "ServesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      errorMessage: "",
      word: "",
      store: useAllServicesStore(),
      tableHeaders: ["id", "صوره", "الاسم", "الوصف"],
      editLink: "/edit-feature-serves",
      viewLink: "/view-feature-serves",
    };
  },
  computed: {
    ...mapState(useAllServicesStore, {
      FeatueServ: (state) => state.FeatueServs,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      const dataToDisplay = this.FeatueServ || []; // إذا كانت غير معرفة، اعطها مصفوفة فارغة
      return dataToDisplay.map((serv) => [
        serv.id,
        serv.image,
        serv.title,
        serv.description,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    async handleSearch() {
      const srvesStore = useAllServicesStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await srvesStore.fetchFeatureServicesStore(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await srvesStore.fetchFeatureServicesStore(1, this.word);

      if (srvesStore.FeatueServs.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },

    handlePageChange(page) {
      const servicestoreStoree = useAllServicesStore();
      servicestoreStoree.fetchFeatureServicesStore(page);
    },
    async deleteServices(id) {
      const servicestoreStoree = useAllServicesStore();
      await servicestoreStoree.deleteServicesStore(id);
    },
  },
  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const servicestoreStoree = useAllServicesStore();
    await servicestoreStoree.fetchFeatureServicesStore();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped></style>
