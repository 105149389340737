<template>
  <div class="relation">
    <header-pages
      title="صله القرابه "
      button="+ اضافة صله قرابه"
      link="/relation-add"
      icon="fa-solid fa-hand-holding-heart"
      v-if="create"
    />

    <div class="alll">
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="handleDeleteRelation"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useRelationsStore } from "@/stores/Relations/RelationsStore";

export default {
  components: { HeaderPages, TablesPageVue },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      errorMessage: "",
      tableHeaders: ["ID", "الاسم"],
      editLink: "/relation-edit",
      viewLink: "/",
    };
  },
  computed: {
    ...mapState(useRelationsStore, {
      relations: (state) => state.relations,
    }),

    tableRows() {
      const dataToDisplay = this.relations;
      return dataToDisplay.map((rel) => [rel.id, rel.title]);
    },

    //   tableRows() {
    //     if (!Array.isArray(this.relations)) {
    //       console.warn("relation is not an array:", this.relations);
    //       return [];
    //     }
    //   },
    //   tablePages() {
    //     return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    //   },
  },
  methods: {
    handlePageChange(page) {
      const relationStore = useRelationsStore();
      relationStore.fetchRelations(page);
    },
    handleDeleteRelation(id) {
      const relationStore = useRelationsStore();
      relationStore.deleteRelation(id);
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const relationStore = useRelationsStore();
    relationStore.fetchRelations();
  },
};
</script>

<style scoped></style>
