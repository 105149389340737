<template>
    <div class="table-responsive">
              <table class="table table-striped">
                <caption class="visually-hidden">
                  قائمة الواجبات والنتائج
                </caption>
                <thead>
                  <tr>
                    <th scope="col">
                      <span class="visually-hidden">تحديد</span>
                    </th>
                    <th scope="col">الواجب</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">اجابات صحيحة</th>
                    <th scope="col">اجابات خاطئة</th>
                    <th scope="col">الدرجة النهائية</th>
                    <th scope="col">
                      <span class="visually-hidden">الإجراءات</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        aria-label="تحديد واجب اختبار قرآن كريم"
                      />
                    </td>
                    <td>اختبار قرآن كريم</td>
                    <td>2023-06-15</td>
                    <td>15 إجابة</td>
                    <td>8 إجابات</td>
                    <td>40/80</td>
                    <td>
                      <button
                        type="button"
                        class="btn ditsels"
                        data-bs-toggle="modal"
                        data-bs-target="#View-details"
                      >
                        عرض التفاصيل
                      </button>
                    </td>
                  </tr>
                  <!-- Repeat other rows with unique data -->
                </tbody>
              </table>
            </div>
</template>

<script>
export default {

}
</script>

<style>

</style>