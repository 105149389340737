<template>
  <header-pages title="حصص المجموعه"></header-pages>
  <div class="view-group">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>الاسم</th>
            <th>من</th>
            <th>الي</th>
            <th>التحكم</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(view, index) in viewgropup" :key="index">
            <td>{{ view.id }}</td>
            <td>{{ view.title }}</td>
            <td>{{ view.start_verse }}</td>
            <td>{{ view.end_verse }}</td>
            <td>
              <div class="puttons">
                <button class="Attendanceabsence" @click="Attendanceabsence">
                  الحضور والغياب
                </button>
                <button
                  class="pindinglive"
                  @click="pindinglive(view)"
                  :disabled="view.isButtonDisabled"
                  :class="{ 'disabled-button': view.isButtonDisabled }"
                >
                  تجهيز اللايف
                </button>
                <button class="startlive" @click="goLive(view.id)">
                  بدأ اللايف
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "GroupView",
  components: {
    HeaderPages,
  },
  data() {
    return {
      group_id: "",
      viewgropup: [],
      live: {},
    };
  },
  methods: {
    Attendanceabsence() {
      this.$router.push("/Attendance-absence");
    },
    async getGroup() {
      try {
        const response = await axios.post("organization_fetch_main_sessions", {
          group_id: this.$route.params.id,
        });
        if (response.data.status == true) {
          this.viewgropup = response.data.data.map((item) => ({
            ...item,
            isButtonDisabled: false,
          }));
        }
      } catch (error) {
        console.log("خطأ في جلب البيانات:", error);
      }
    },
    async goLive(id) {
      try {
        const reslive = await axios.post("join_room", {
          session_id: id,
        });
        if (reslive.data.status === true) {
          this.live = reslive.data.data;
          console.log(this.live);

          this.$router.push({
            path: `/live-teacher/${id}`,
            query: {
              host_code: this.live.host_code,
              session_id: id,
            },
          });
        }
      } catch (error) {
        console.log("خطأ في بدء الجلسة:", error);
      }
    },
    async pindinglive(view) {
      try {
        const reslive = await axios.post("create_room", {
          session_id: view.id,
        });
        if (reslive.data.status == true) {
          console.log("good");
          Swal.fire("تم بنجاح", "تم بنجاح", "success");
          view.isButtonDisabled = true;
        } else {
          console.log("error");
          Swal.fire({
            icon: "error",
            title: "خطاء",
            text: reslive.data.message,
          });
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
  mounted() {
    this.getGroup();
  },
};
</script>

<style scoped>
/* أنماط الزر المعطل */
.disabled-button {
  background-color: #d3d3d3; /* اللون الرمادي */
  color: #a9a9a9; /* لون النص */
  cursor: not-allowed; /* تغيير شكل المؤشر */
}
.puttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
