<template>
  <div class="col-lg-6 col-md-12 col-12">
    <div class="low-lesson">
      <div class="crad-lesson">
        <div class="img-lesson">
          <img src="@/assets/mida/vid.png" class="" alt="" />
        </div>
        <div class="parnt-card-lesson">
          <div class="text-card-lesson">
            <p>{{ item.session_type }}</p>
            <h3>
              {{ item.title }}
            </h3>
            <span>{{ item.group_name }}</span>
          </div>
          <div class="lasts">
            <div class="las">
              <!-- <div class="lesson-last-card">
                <img src="@/assets/mida/small pic.png" alt="" />
                <h3>الشيخ:أحمد عبد الملك</h3>
              </div> -->
              <div class="lesson-last-1-card">
                <i class="fa-regular fa-clock"></i>
                <h6>وقت البدء: {{ item.start_time }} : {{ item.date }}</h6>
              </div>
            </div>
          </div>
          <div class="botton-lesson gap-5">
            <button
              @click="goToLesson(item)"
              type="button"
              class="btn inside"
              :disabled="!item.is_live"
            >
              الدخول للغرفة</button
            >0
            <!-- <a href="#">
              <button type="button" class="btn inside">عرض الدرس</button>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    async goToLesson(lesson) {
      // console.log(lesson, "dada");
      const response = await axios.post("/join_room", {
        session_id: lesson.id,
      });
      if (response.data.status == true) {
        this.$router.push({
          path: `/live-teacher/${lesson.id}`,
          query: {
            live_id: lesson.live.id,
            host_code: lesson.live.host_code,
            session_id: lesson.id,
          },
        });
      }
    },
  },
};
</script>

<style></style>
