<template>
  <div class="col-lg-6 col-md-12 col-12 student">
    <div class="percentage_jobs w-100">
      <h5 style="text-align: right">معدل توزيع الطالب</h5>
      <div class="card">
        <Chart
          type="bar"
          :data="chartData"
          :options="chartOptions"
          class="h-[30rem] w-[30rem]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chart from "primevue/chart";

export default {
  data() {
    return {
      chartData: null,
      chartOptions: null,

      user_counts: [],
      months: [],
    };
  },
  components: {
    Chart,
  },
  methods: {
    setChartData() {
      return {
        labels: this.months,
        datasets: [
          {
            type: "line",
            label: "اجراء المهام",
            backgroundColor: "#06797E33",
            borderColor: "#06797E",
            borderWidth: 1,
            fill: true,
            data: this.user_counts,
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    },

    async getData() {
      await axios
        .post("teacher_interacted_rate_with_organization")
        .then((res) => {
          // this.chartData =
          // console.log(res.data.data);
          this.months = res.data.data.months;
          this.user_counts = res.data.data.user_counts;
          this.chartData = this.setChartData(); // Set chart data after fetch
          this.chartOptions = this.setChartOptions(); // Set chart options
        });
    },
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
    this.getData();
  },
};
</script>

<style></style>
