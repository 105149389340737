<template>
  <section class="index_cards">
    <div class="content-main-group">
      <section class="container">
        <div class="main-group">
          <div class="text-librar">
            <h4>المجموعات</h4>
            <div class="p-text">
              <p>
                <img class="squr" src="@/assets/mida/green squr.png" alt="" />هي
                المجموعات التي تشارك بها مع طلابك الخاصين بك .
              </p>
              <p>
                <img class="squr" src="@/assets/mida/green squr.png" alt="" />في
                المجموعات تستطيع معرفة مواعيد الدروس المباشرة و القادمة لك
                بسهولة .
              </p>
              <p>
                <img
                  class="squr"
                  src="@/assets/mida/green squr.png"
                  alt=""
                />يمكنك ايضا معرفة طلاب كلمجموعة بجميع انشطتهم و يمكنك التواصل
                معهم او مع اولياء امورهم بسهولة .
              </p>
            </div>
          </div>
          <div class="row">
            <GrousCards v-for="group in groups" :key="group" :group="group" />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import GrousCards from "@/components/groups/cards.vue";
import axios from "axios";
export default {
  data() {
    return {
      groups: [],
    };
  },
  methods: {
    getGroups() {
      axios.post("teacher_groups").then((res) => {
        this.groups = res.data.data;
      });
    },
  },

  mounted() {
    this.getGroups();
  },
  components: { GrousCards },
};
</script>
