<template>
  <button
    type="button"
    class="btn ditsels"
    data-bs-toggle="modal"
    data-bs-target="#View-details"
  >
    عرض التفاصيل
  </button>
  <div
    class="modal fade"
    id="View-details"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog View-details">
      <div class="modal-content">
        <div class="modal-header"></div>
        <div class="modal-body View-details">
          <div class="viwe">
            <div class="main-Integrated-testing">
              <div class="Integrated testing">
                <img src="mida/accordind.png" alt="" />
              </div>
              <div class="Integrated-testing-text">
                <h4>{{ data.title }}</h4>
                <h3>{{ data.group_title }}</h3>
              </div>
            </div>
            <!-- <div class="button-viwe-details">
              <button
                type="button"
                class="btn add-rat"
                data-bs-toggle="modal"
                data-bs-target="#add-rate"
              >
                اضافة تقييم عام
              </button>
            </div> -->
          </div>
          <div class="text-details">
            <h4>
              <i class="fa-regular fa-square-check"></i>الاجابات الصحيحة:
              <span>{{ data.correct_answer_count }}</span>
            </h4>
            <h4>
              <i class="fa-solid fa-square-xmark"></i>الاجابات الخاطئة:
              <span>{{ data.wrong_answer_count }}</span>
            </h4>
            <h4>
              <i class="fa-regular fa-calendar-days"></i>تاريخ الواجب:
              <span>{{ data.date }} </span>
            </h4>
            <h4>
              <i class="fa-regular fa-calendar-days"></i>الدرجة النهائية:
              <span>{{ data.final_grade }}</span>
            </h4>
          </div>
          <hr />
          <div class="worng text-end">
            <h4>الاجابات الخاطئة</h4>
          </div>

          <div
            v-for="(wrong, index) in data.wrong_answers"
            class="mt-2"
            :key="index"
          >
            <div class="lest-details text-end">
              <h3 class="mb-0">{{ wrong.question_title }}</h3>
              <h6 class="mb-0">{{ wrong.wrong_answer_title }}</h6>
              <p class="mb-0">
                الاجابة الصحيحة: {{ wrong.correct_answer_title }}
              </p>
            </div>
          </div>

          <!-- <div class="line-af"></div> -->
        </div>
        <!-- <div class="modal-footer"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { data } from "jquery";

export default {
  data() {
    return {
      data: {},
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getDetais() {
      axios
        .post("teacher_student_exam_details", {
          exam_result_id: this.item.id,
        })
        .then((response) => {
          this.data = response.data.data; // Store the response in `data`
          // console.log(this.data, "data");
        })
        .catch((error) => {
          console.error("Error fetching details:", error);
        });
    },
  },

  mounted() {
    this.getDetais();
  },

  watch: {
    item() {
      this.getDetais();
    },
  },
};
</script>
