<template>
  <div class="container">
    <div class="row">
      <div
        class="col-lg-6 col-md-12 col-12"
        v-for="item in this.lessons"
        :key="item.id"
      >
        <div class="low-lesson">
          <div class="crad-lesson">
            <div class="img-lesson">
              <img src="@/assets/mida/vid.png" class="" alt="" />
            </div>
            <div class="parnt-card-lesson">
              <div class="text-card-lesson">
                <p>{{ item.session_type }}</p>
                <h3>
                  {{ item.title }}
                </h3>
                <span>{{ item.group_name }}</span>
              </div>
              <div class="lasts">
                <div class="las">
                  <div class="lesson-last-1-card">
                    <i class="fa-regular fa-clock"></i>
                    <h6>وقت البدء: {{ item.start_time }} : {{ item.date }}</h6>
                  </div>
                </div>
              </div>
              <div class="botton-lesson gap-5">
                <button
                  @click="goToLesson(item)"
                  type="button"
                  class="btn inside"
                  :disabled="!item.is_live"
                >
                  الدخول للغرفة
                </button>
                <!-- <a href="#">
                  <button type="button" class="btn inside">عرض الدرس</button>
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lessons: [],
    };
  },
  methods: {
    getLessons() {
      axios
        .post("teacher_group_sessions", { group_id: this.$route.params.id })
        .then((res) => {
          this.lessons = res.data.data;
          //   console.log(res.data.data);
        });
    },
    goToLesson(lesson) {
      // console.log(lesson, "dada");

      this.$router.push(`/live-teacher/${lesson?.id}`);
    },
  },
  mounted() {
    this.getLessons();
  },
};
</script>
