<template>
  <!-- ======= Main ======= -->
  <section class="index_cards">
    <div class="parent">
      <div class="container">
        <div class="row">
          <h4 class="h4">الرئيسية / اعدادات الحساب</h4>

          <div class="row">
            <div class="col-lg-3 col-md-5 col-12">
              <div
                class="nav flex-column nav-pills shape-tab"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div class="title-bab">
                  <img
                    :src="user.image || '@/assets/mida/user-seting.png'"
                    width="60"
                    height="60"
                    class="rounded-circle"
                    alt=""
                  />
                  <div class="user">
                    <h5>{{ user.name }}</h5>
                    <h6>{{ user.status }}</h6>
                  </div>
                </div>
                <button
                  class="nav-link active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <div class="seting">
                    <i class="fa-regular fa-user"></i>
                    <h5>اعدادات الحساب</h5>
                  </div>
                </button>

                <!-- <button
                  class="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <div class="seting">
                    <i class="fa-regular fa-comments"></i>
                    <h5>الرسائل</h5>
                  </div>
                </button> -->
                <button
                  class="nav-link"
                  id="v-pills-password-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-password"
                  aria-selected="false"
                >
                  <div class="seting">
                    <i class="fa-solid fa-unlock-keyhole"></i>
                    <h5>تغيير كلمة المرور</h5>
                  </div>
                </button>
                <!-- <button
                  class="nav-link"
                  id="v-pills-notification-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-notification"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-notification"
                  aria-selected="false"
                >
                  <div class="seting">
                    <i class="fa-regular fa-bell"></i>
                    <h5>الاشعارات</h5>
                  </div>
                </button> -->

                <button
                  class="nav-link"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#out"
                >
                  <div class="seting">
                    <i class="fa-solid fa-right-from-bracket out"></i>
                    <h5>تسجيل الخروج</h5>
                  </div>
                </button>
              </div>
              <div class="modal" id="out" tabindex="-1">
                <div class="modal-dialog out">
                  <div class="modal-content">
                    <div class="modal-header"></div>
                    <div class="modal-body out">
                      <h4>هل تريد تسجيل الخروج؟</h4>

                      <button
                        type="submit"
                        class="btn PrimaryButton mx-4"
                        @click.prevent="logout"
                      >
                        تأكيد
                      </button>

                      <button type="submit" class="btn PrimaryButton">
                        الغاء
                      </button>
                    </div>

                    <div class="modal-footer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-7 col-12">
              <div class="tab-content" id="v-pills-tabContent">
                <Profile />

                <!-- <Message /> -->

                <Password />

                <!-- <Notification /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Profile from "@/components/setting/TeacherSetting/ProfileAccount.vue";
// import Message from "@/components/setting/TeacherSetting/Message.vue";
import Password from "@/components/setting/TeacherSetting/Password.vue";
// import Notification from "@/components/setting/TeacherSetting/Notifications.vue";
export default {
  name: "ProfileSetting",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {
    Profile,
    // Message,
    Password,
    // Notification,
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.push("/login");
      location.reload();
    },
  },
};
</script>
