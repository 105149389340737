<template>
  <section class="index_cards">
    <div class="content-main-lib">
      <div class="container">
        <div class="Lesson-details">
          <div class="test-details">
            <h4>مجموعة العهد الحديث</h4>
            <!-- <button
              type="button"
              class="btn Lesson-details"
              data-bs-toggle="modal"
              data-bs-target="#add-rate"
            >
              اضافة تقييم عام للطالب
            </button> -->
          </div>
        </div>
        <div class="row">
          <WhatsLove />
          <!-- <HomeWork /> -->
        </div>
        <div class="row">
          <StudentProcess />
          <LastActions />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Chart from "primevue/chart";
import WhatsLove from "@/components/NewTestamentCollection/What'sLove.vue";
import HomeWork from "@/components/NewTestamentCollection/HomeWork.vue";
import StudentProcess from "@/components/NewTestamentCollection/StudentProcess.vue";
import LastActions from "@/components/NewTestamentCollection/LastActions.vue";
export default {
  components: {
    Chart,
    WhatsLove,
    HomeWork,
    StudentProcess,
    LastActions,
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,

      chartDataLine: null,
      chartOptionsLine: null,
    };
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();

    this.chartDataLine = this.setChartDataLine();
    this.chartOptionsLine = this.setChartOptionsLine();
  },
  methods: {
    setChartDataLine() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: documentStyle.getPropertyValue("--p-cyan-500"),
            tension: 0.4,
          },
        ],
      };
    },
    setChartOptionsLine() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },

    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: documentStyle.getPropertyValue("--p-cyan-500"),
            borderColor: documentStyle.getPropertyValue("--p-cyan-500"),
            data: [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    },
  },
};
</script>
