<template>
  <div class="groups">
    <div class="head">
      <h4>المجموعات</h4>
      <router-link v-if="create" :to="`/add-group/${$route.params.id}`">
        <button>اضافة مجموعة</button>
      </router-link>
    </div>
  </div>
  <div class="alll">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>اسم المجموعه</th>
            <th>تاريخ البدايه</th>

            <th>الاعدادات</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="groups.length === 0">
            <td colspan="5" style="text-align: center">
              لا توجد مجموعات متاحة.
            </td>
          </tr>
          <tr v-for="group in groups" :key="group.id">
            <td>{{ group.id }}</td>
            <td>{{ group.title }}</td>
            <td>{{ group.start_date }}</td>
            <td class="puttons">
              <div class="allpyn">
                <i
                  style="color: red; font-size: 1rem; cursor: pointer"
                  class="fa-regular fa-trash-can"
                  @click="deleteGroup(group.id)"
                  v-if="deletes"
                ></i>

                <router-link
                  :to="
                    '/edit-groups-show/' +
                    this.$route.params.id +
                    '/' +
                    group.id
                  "
                  v-if="edit"
                >
                  <i
                    style="color: var(--main); font-size: 1rem"
                    class="fa-regular fa-pen-to-square"
                  ></i>
                </router-link>
                <router-link
                  :to="'/exams-group/' + group.id"
                  title="الامتحانات"
                  v-if="groups_exams"
                >
                  <i
                    style="color: var(--main); font-size: 1rem"
                    class="fa-solid fa-sheet-plastic"
                  ></i>
                </router-link>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i
                      style="color: var(--secondary)"
                      class="fa-solid fa-gears"
                    ></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li v-if="organizations_sessions_add">
                      <router-link
                        :to="`/add-lesson-group/${$route.params.id}/${group.id}`"
                      >
                        اضافة الحلقات
                      </router-link>
                    </li>

                    <li v-if="organizations_group_student_add">
                      <router-link
                        :to="`/add-student-group/${$route.params.id}/${group.id}`"
                      >
                        اضافة طلاب للمجموعه
                      </router-link>
                    </li>
                    <li v-if="organizations_group_student_read">
                      <router-link
                        :to="`/show-student-group/${$route.params.id}/${group.id}`"
                      >
                        عرض طلاب المجموعه
                      </router-link>
                    </li>
                    <li v-if="organizations_sessions_read">
                      <router-link :to="`/view-group/${group.id}`">
                        عرض حصص المجموعه
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "GroupsIndexShow",
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      organizations_sessions_add: false,
      organizations_sessions_read: false,
      organizations_group_student_add: false,
      organizations_group_student_read: false,
      groups_exams: false,
      groups: [],
      // id: this.$route.params.id,
    };
  },
  methods: {
    async fetchGroups() {
      try {
        const res = await axios.post("fetch_groups", {
          course_id: this.$route.params.id,
        });
        if (res.data.status == true) {
          this.groups = res.data.data.data;
        } else {
          console.error("Failed to fetch groups:", res.data.message);
        }
      } catch (error) {
        console.error("خطاء في جلب المجموعات:", error);
      }
    },
    async deleteGroup(groupId) {
      try {
        const confirm = await Swal.fire({
          title: "هل أنت متأكد؟",
          text: "لن تتمكن من استعادة هذه المجموعة بعد الحذف!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "نعم، احذفها!",
          cancelButtonText: "إلغاء",
        });

        if (confirm.isConfirmed) {
          const res = await axios.post("delete_group", { id: groupId });
          if (res.data.status === true) {
            Swal.fire(
              "تم الحذف!",
              res.data.message || "تم حذف المجموعة بنجاح",
              "success"
            );
            this.groups = this.groups.filter((group) => group.id !== groupId);
          } else {
            Swal.fire(
              "خطأ!",
              res.data.message || "حدث خطأ أثناء الحذف",
              "error"
            );
          }
        }
      } catch (error) {
        console.error("خطأ أثناء الحذف:", error);
        Swal.fire("خطأ!", "حدث خطأ غير متوقع", "error");
      }
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
      if (role.name == "groups_exams") {
        role.maps?.filter((map) => {
          if (map.name == "read") {
            this.groups_exams = true;
            // console.log(this.read_groups, "read_groups");
          }
        });
      }
      if (role.name == "organizations_sessions") {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.organizations_sessions_add = true;

            // console.log(this.read_groups, "read_groups");
          }
          if (map.name == "read") {
            this.organizations_sessions_read = true;
            // console.log(this.read_groups, "read_groups");
          }
        });
      }
      if (role.name == "organizations_group_student") {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.organizations_group_student_add = true;
            // console.log(this.read_groups, "read_groups");
          }
          if (map.name == "read") {
            this.organizations_group_student_read = true;
            // console.log(this.read_groups, "read_groups");
          }
        });
      }
    });

    this.fetchGroups();
  },
};
</script>

<style scoped lang="scss">
.allpyn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4 {
  font-family: "regular";
}
button {
  background-color: var(--main) !important;
  color: var(--secondary) !important;
  border-radius: 5px !important;
  border: 0 !important;
  padding: 0.5rem 1rem !important;
  font-family: "regular" !important;
  transition: 0.7s !important;
  border: 1px solid var(--main) !important;
  width: 100% !important;
  margin: 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}
ul.dropdown-menu.show {
  padding: 0.5rem;
  text-align: start;
  width: 167px;
}
a {
  text-decoration: none;
  color: var(--main);
}
.table-responsive {
  .dropdown {
    position: static !important;
  }
}
</style>
