<template>
  <div class="col-lg-6 col-md-12 col-12 activity">
    <div
      class="lesson"
      style="display: flex; justify-content: space-between; border-radius: 10px"
    >
      <h5>النشاطات القادمة</h5>

      <RouterLink to="/lesson-next">
        <h5 class="fs-6">عرض الكل</h5>
      </RouterLink>
    </div>

    <div
      class="act w-50 mb-3 mx-4 w-75"
      v-for="activity in activities"
      :key="activity.id"
    >
      <h4>{{ activity.title }}</h4>
      <h5>{{ activity.group_name }}</h5>
      <span class="date">{{ activity.date }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      activities: [],
    };
  },
  methods: {
    getActivity() {
      axios.post("teacher_upcoming_group_activities").then((res) => {
        this.activities = res.data.data;
        // console.log(res.data.data);
      });
    },
  },

  mounted() {
    this.getActivity();
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: "regular";
}
.activity {
  background-color: white;
  border-radius: 10px;
  width: 32.3%;
  //   margin-bottom: 178px !important;
}
.dd {
  width: 90%;
  margin-right: 20px;
}
.student {
  background: white;
  border-radius: 14px;
  padding: 11px;
  margin-right: 21px;
  width: 60%;
}
.act {
  position: relative;
  border: 1px solid #00000029;
  /* width: fit-content; */
  padding: 0.6rem 2rem;
  margin-top: 16px;
  border-radius: 6px;
  margin-bottom: 10px;
  .date {
    position: absolute;
    top: 19%;
    transform: translateY(-70%);
    background: #06797e;
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
    border-top-left-radius: 20px;
    // right: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.4rem 1rem;
    width: 4rem;

    right: -1rem;
    margin-block: 0.8rem;

    text-align: center;
  }
  h4 {
    font-size: 15px;
    margin-right: 24px;
  }
  h5 {
    font-size: 15px;
    margin-right: 24px;
    color: #8d8d8d;
  }

  img {
    position: absolute;
    top: -21px;
    right: -8px;
    height: 74px;
  }
}
.lesson {
  padding: 9px;

  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    color: var(--main);
  }
}
.images {
  background: white;
  border-radius: 10px;

  img {
    margin-top: 59px;
    margin-right: 164px;
    margin-bottom: 64px;
  }
}
h4 {
  font-size: 19px;
  font-family: "regular";
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: 0;
  margin-left: auto;
}
.all-info {
  display: flex;
  gap: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
}
.all {
  background: #f0eeee;
}

.info {
  display: flex;
  align-items: center;
  background-color: #eef2f2;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 1rem 0;
  padding-left: 85px;
  margin-bottom: 33px;
  margin-top: 12px;

  img {
    background-color: #b2d23f;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
  h2 {
    font-family: "regular";
    font-size: 17px;
  }
  h3 {
    font-size: 14px;
    font-family: "regular";
    font-weight: bold;
  }
}

@media screen and (max-width: 425px) {
  .images {
    margin-bottom: 15px;
    img {
      margin-right: 110px;
    }
  }
  .all-info {
    display: block;
  }
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 89.8%;
  }
  .activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 90.3%;
    margin-right: 17px;
  }
}

@media screen and (max-width: 768px) {
  .images img {
    margin-top: 59px;
    margin-right: 118px;
    margin-bottom: 64px;
  }
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 93.3%;
  }
  .activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 94.3%;
    margin-right: 18px;
    margin-bottom: 0 !important;
  }
}

// @media screen and (max-width: 1440px) {
//   .imgg {
//     flex: 0 0 auto;
//     width: 97.3%;
//     margin-bottom: 16px;
//   }
//   .col-lg-4 {
//     flex: 0 0 auto;
//     width: 97.5%;
//   }

//   .student {
//     background: white;
//     border-radius: 14px;
//     padding: 11px;
//     margin-right: 21px;
//     width: 94%;
//   }
//   .activity {
//     flex: 0 0 auto;
//     width: 94.5%;
//     margin-right: 17px;
//   }
//   .info {
//     display: flex;
//     align-items: center;
//     background-color: #eef2f2;
//     border-radius: 10px;
//     padding: 0.5rem;
//     margin: 1rem 0;
//     padding-left: 365px;
//     margin-bottom: 33px;
//     margin-top: 12px;
//   }
// }
</style>
