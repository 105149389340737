<template>
  <div class="col-lg-5 col-md-12 col-12">
    <div class="all-details p-2 overflow-auto" style="height: 25rem">
      <div class="efacts">
        <h4>التفاعلات الأخيرة</h4>
        <!-- <a href="#"> <p>عرض الكل</p></a> -->
      </div>
      <div class="img-side-text" v-for="item in data" :key="item">
        <div class="img-side">
          <img src="@/assets/mida/side.png" alt="" />
        </div>
        <div class="text-side w-100">
          <h4>
            {{ item.title }}
          </h4>
          <p>{{ item.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getData() {
      axios
        .post("teacher_group_last_sessions", {
          group_id: this.$route.params.id,
        })
        .then((res) => {
          this.data = res.data.data;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
