<template>
  <div
    class="modal fade"
    id="View-details"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog View-details">
      <div class="modal-content">
        <div class="modal-header"></div>
        <div class="modal-body View-details">
          <div class="viwe">
            <div class="main-Integrated-testing">
              <div class="Integrated testing">
                <img src="mida/accordind.png" alt="" />
              </div>
              <div class="Integrated-testing-text">
                <h4>
                  واجب متكامل عن أول سورة البقرة من الآية 10 - 20 بالتجويد
                </h4>
                <h3>مجموعة العهد الحديث</h3>
              </div>
            </div>
            <div class="button-viwe-details">
              <button
                type="button"
                class="btn add-rat"
                data-bs-toggle="modal"
                data-bs-target="#add-rate"
              >
                اضافة تقييم عام
              </button>
            </div>
          </div>
          <div class="text-details">
            <h4>
              <i class="fa-solid fa-list-check"></i>اجمالى الأسألة:<span
                >20 سؤال</span
              >
            </h4>
            <h4>
              <i class="fa-regular fa-square-check"></i>الاجابات الصحيحة:<span
                >10</span
              >
            </h4>
            <h4>
              <i class="fa-solid fa-square-xmark"></i>الاجابات الخاطئة:<span
                >10</span
              >
            </h4>
            <h4>
              <i class="fa-regular fa-calendar-days"></i>تاريخ الواجب:<span
                >22 مايو - 8:30 م</span
              >
            </h4>
            <h4>
              <i class="fa-regular fa-calendar-days"></i>الدرجة النهائية:<span
                >80 / 40</span
              >
            </h4>
          </div>
          <hr />
          <div class="worng">
            <h4>الاجابات الخاطئة</h4>
          </div>

          <div class="lest-details">
            <h3>جاءت ( تِلْكَ حُدُودُ اللَّهِ .. ) في أول موضع</h3>
            <h6>فلا تقربوها</h6>
            <p>الاجابة الصحيحة : فلا تعتدوها</p>
          </div>
          <div class="lest-details-1">
            <h3>سورة البقرة تعتبر سورة</h3>
            <h6>مكية</h6>
            <p>الاجابة الصحيحة : مدنية</p>
          </div>
          <!-- <div class="line-af"></div> -->
        </div>
        <!-- <div class="modal-footer"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
