import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useAppstudentStore = defineStore("appstudent", {
  state: () => ({
    appstudent: [],
  }),
  actions: {
    async AddAppstudent(appstudentData) {
      console.log("Appstudent data inside store:", appstudentData); // Log the data being passed to the store

      const formData = new FormData();

      // If an image file exists, append it
      if (appstudentData.imageFile) {
        console.log("Appending image:", appstudentData.imageFile);
        formData.append("image", appstudentData.imageFile);
      }

      // Ensure 'type' is appended correctly
      if (appstudentData.type) {
        console.log("Appending type:", appstudentData.type); // Check if 'type' is correctly included
        formData.append("type", appstudentData.type);
      }

      // Log other form data
      Object.keys(appstudentData).forEach((key) => {
        if (key !== "imageFile" && key !== "image" && key !== "certificateFile") {
          if (appstudentData[key] !== null && appstudentData[key] !== undefined && appstudentData[key] !== "") {
            formData.append(key, appstudentData[key]);
          }
        }
      });

      try {
        const response = await axios.post("store_application_info", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === true) {
          this.appstudent = response.data.data;
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Appstudent added successfully",
          });
          router.push("/"); // Redirect to a different route after successful submission
          console.log(this.appstudent, "appstudent data updated");
        } else {
          console.error("Error fetching appstudent.");
        }
      } catch (error) {
        console.error("❌ خطأ أثناء الإرسال:", error);

        let errorMessage = "حدث خطأ أثناء الإرسال. حاول مرة أخرى.";

        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: errorMessage
        });
      }
    },



    async fetchDataStudent() {
      try {
        const response = await axios.post("fetch_application_info", {
          type: 2,
        });
        if (response.data.status === true) {
          this.appstudent = response.data.data;
          console.log(this.appstudent, "appstudent data updated");
        } else {
          console.error("Error fetching appstudent.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
});
