<template>
  <div
    class="tab-pane fade show active"
    id="v-pills-home"
    role="tabpanel"
    aria-labelledby="v-pills-home-tab"
  >
    <div class="head-tab">
      <h6>اعدادات الحساب</h6>
    </div>
    <form class="name" @submit.prevent="saveProfile">
      <!-- Upload image -->
      <div class="col-lg-3 col-md-5 col-12">
        <div class="container">
          <div class="avatar-upload">
            <div class="avatar-edit">
              <div class="col-lg-12 col-md-12 col-12">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  @change="onImageChange"
                  ref="fileInput"
                />
                <label for="imageUpload" class="btn"> اضافة صورة </label>
              </div>
            </div>
            <div class="avatar-preview">
              <!-- Display the selected image preview -->
              <div
                v-if="imagePreview"
                :style="{ backgroundImage: 'url(' + imagePreview + ')' }"
                class="img"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Upload image -->

      <div class="col-lg-7 col-md-10 col-10">
        <div class="form-group setting">
          <input
            type="text"
            v-model="name"
            class="form-control"
            placeholder="الاسم كاملا"
          />
          <input
            type="tel"
            v-model="phone"
            class="form-control"
            placeholder="رقم الجوال"
          />
          <input
            type="email"
            v-model="email"
            class="form-control"
            placeholder="البريد لاالكتروني ( اختياري )"
          />
        </div>
        <button class="btn btn-success">حفظ</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      imagePreview: null,
      selectedImage: null,
      name: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    // Handle image change and preview
    onImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        const reader = new FileReader();
        reader.onloadend = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    // Handle save and send the image as binary
    saveProfile() {
      const formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("name", this.name);
      formData.append("phone", this.phone);
      formData.append("email", this.email);

      axios
        .post("teacher_update_account_settings", formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "تم التعديل",
            text: res.data.message,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "حدث خطأ",
            text: err.response.data.message,
          });
        });
    },

    getProfile() {
      axios.post("teacher_fetch_account_settings").then((res) => {
        this.name = res.data.data.name;
        this.phone = res.data.data.phone;
        this.email = res.data.data.email;
        this.imagePreview = res.data.data.image;
      });
    },
 
  },

  mounted() {
    this.getProfile();
  },
};
</script>

<style scoped>
.avatar-preview {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
}
</style>
