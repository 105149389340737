<template>
  <div class="counter_sec">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/note-1.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">اجمالى الواجبات</h4>
            <p class="counter fw-bold" data-target="80">80</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/note-3.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">الواجبات المنجزة</h4>
            <p class="counter fw-bold" data-target="20">20</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/cont88.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">الواجبات الغير منجزة</h4>
            <p class="counter fw-bold" data-target="56">56</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
