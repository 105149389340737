<template>
  <div class="add-lesson">
    <div class="head">
      <h4 id="addlesson">اضافة حصه</h4>
      <h4 id="editlesson" style="display: none">تعديل الحصه</h4>
      <button
        type="button"
        class="lesson"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        @click="getLessonsGroup"
        v-if="read"
      >
        حصص المنهج
      </button>
      <div class="new-lesson" id="isnew" v-if="create">
        <input type="checkbox" v-model="is_new" />
        <label for="name">اضافة حصه جديده</label>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1
              style="font-family: 'bold'"
              class="modal-title fs-5"
              id="exampleModalLabel"
            >
              حصص المنهج
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">اسم الحلقه</th>
                    <th scope="col">المرحله</th>
                    <th scope="col">السورة</th>
                    <th scope="col">من الايه</th>
                    <th scope="col">الي الايه</th>
                    <th scope="col">نوع الحصه</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lesson in LessonsGroup" :key="lesson.id">
                    <td>{{ lesson.id }}</td>
                    <td>{{ lesson.title }}</td>
                    <td>{{ lesson.stage_title }}</td>
                    <td>{{ lesson.surah_title }}</td>
                    <td>{{ lesson.start_ayah_title }}</td>
                    <td>{{ lesson.end_ayah_title }}</td>
                    <td>{{ lesson.session_type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              style="font-family: 'regular'"
            >
              اغلاق
            </button>
          </div>
        </div>
      </div>
    </div>
    <form action="" @submit.prevent="submitForm">
      <div class="row" v-if="!is_new">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اختر الحلقه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="namelesson_value"
            :close-on-select="true"
            :options="namelessonOptions"
            placeholder="أختر الحلقه"
            label="title"
            track-by="id"
            @update:modelValue="getSurah"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">المرحله</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="stage_value"
            :close-on-select="true"
            :options="stageOptions"
            placeholder="أختر المرحله"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">السورة</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="surah_value"
            :close-on-select="true"
            :options="surahOptions"
            placeholder="السورة"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">من الايه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="ayafrom_value"
            :close-on-select="true"
            :options="ayafromOptions"
            placeholder="الايه"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الي الايه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="ayato_value"
            :close-on-select="true"
            :options="ayatoOptions"
            placeholder="الايه"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">نوع الحصه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="lessontype_value"
            :close-on-select="true"
            :options="lessontypeOptions"
            placeholder="نوع الحصه"
            label="title"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اختر المدرس</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="teatcher_value"
            :close-on-select="true"
            :options="teatcherOptions"
            placeholder="أختر المدرس"
            label="name"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اختر اليوم</label>
          <div class="input">
            <input v-model="start_date" placeholder="من " type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">وقت البدايه</label>
          <div class="input">
            <input v-model="start_time" placeholder="من " type="time" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">وقت النهايه</label>
          <div class="input">
            <input v-model="end_time" placeholder="الي " type="time" />
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="title">اسم الحلقه</label>
          <div class="input">
            <input
              type="text"
              placeholder="اسم الحلقه"
              v-model="newLessonName"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">المرحله</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="stage_value_new"
            :close-on-select="true"
            :options="stageOptionsNew"
            placeholder="أختر المرحله"
            label="title"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">السورة</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="surah_value_new"
            :close-on-select="true"
            :options="surahOptionsNew"
            placeholder="السورة"
            label="title"
            track-by="id"
            @update:modelValue="getAyahFromNew"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">من الايه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="ayafrom_value_new"
            :close-on-select="true"
            :options="ayafromOptionsNew"
            placeholder="الايه"
            label="title"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الي الايه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="ayato_value_new"
            :close-on-select="true"
            :options="filteredAyatoOptions"
            placeholder="الايه"
            label="title"
            track-by="id"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">نوع الحصه</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="lessontype_value"
            :close-on-select="true"
            :options="lessontypeOptions"
            placeholder="نوع الحصه"
            label="title"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اختر المدرس</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="teatcher_value"
            :close-on-select="true"
            :options="teatcherOptions"
            placeholder="أختر المدرس"
            label="name"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اختر اليوم</label>
          <div class="input">
            <input v-model="start_date" placeholder="من " type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">وقت البدايه</label>
          <div class="input">
            <input v-model="start_time" placeholder="من " type="time" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">وقت النهايه</label>
          <div class="input">
            <input v-model="end_time" placeholder="الي " type="time" />
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" id="save" type="submit">حفظ</button>
        <button class="save" id="edit" type="submit" style="display: none">
          تعديل
        </button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>

    <!-- جدول الحصص -->
    <h4 style="font-family: 'bold'; margin-top: 1rem">جدول الحصص</h4>
    <div class="sqdwel">
      <!-- يظهر عند تحديد صف -->
      <div class="allde">
        <div v-if="selectedRowIndex !== null" class="row-options">
          <p>الصف المحدد هو: رقم {{ selectedRowIndex + 1 }}</p>
          <label for="newPosition">إعادة ترتيب الصف:</label>
          <input
            id="newPosition"
            type="number"
            v-model="newPosition"
            :min="1"
            :max="lessonTable.length"
            @blur="reorderRow"
          />
          <button
            class="replace"
            @click="reorderRow(lessonTable[selectedRowIndex]?.id)"
          >
            إعادة ترتيب
          </button>
        </div>
      </div>

      <!-- حقل البحث -->

      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="ابحث عن درس"
        />
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th :class="{ sorted: sortKey === 'title' }">
                الترتيب
                <i
                  :class="
                    sortKey === 'title'
                      ? sortOrder === 1
                        ? 'fa-solid fa-arrow-up'
                        : 'fa-solid fa-arrow-down'
                      : ''
                  "
                ></i>
              </th>
              <th :class="{ sorted: sortKey === 'title' }">اسم الحلقه</th>
              <th :class="{ sorted: sortKey === 'stage_title' }">المرحله</th>
              <th :class="{ sorted: sortKey === 'surah_title' }">السورة</th>
              <th :class="{ sorted: sortKey === 'start_ayah_title' }">
                من الايه
              </th>
              <th :class="{ sorted: sortKey === 'end_ayah_title' }">
                الي الايه
              </th>
              <th :class="{ sorted: sortKey === 'session_type' }">نوع الحصه</th>
              <th :class="{ sorted: sortKey === 'teacher_name' }">المدرس</th>
              <th :class="{ sorted: sortKey === 'date' }">اليوم</th>
              <th :class="{ sorted: sortKey === 'start_time' }">وقت البدايه</th>
              <th :class="{ sorted: sortKey === 'end_time' }">وقت النهايه</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="lessonTable.length === 0">
              <td colspan="12" style="text-align: center !important">
                لا يوجد حصص
              </td>
            </tr>
            <tr
              v-for="(lesson, index) in sortedLessons"
              :key="lesson.id"
              :class="{ selected: selectedRowIndex === index }"
              @click="selectRow(index)"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ lesson.title }}</td>
              <td>{{ lesson.stage_title }}</td>
              <td>{{ lesson.surah_title }}</td>
              <td>{{ lesson.start_ayah_title }}</td>
              <td>{{ lesson.end_ayah_title }}</td>
              <td>{{ lesson.session_type }}</td>
              <td>{{ lesson.teacher_name }}</td>
              <td>{{ lesson.date }}</td>
              <td>{{ formatTime(lesson.start_time) }}</td>
              <td>{{ formatTime(lesson.end_time) }}</td>
              <td>
                <div class="all-action">
                  <div
                    @click="DeletRow(lesson.id)"
                    class="delet"
                    v-if="deletes"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </div>
                  <div @click="updateRow(lesson.id)" class="edit" v-if="edit">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination" v-if="pagination.last_page > 1">
      <button
        class="prev"
        :disabled="pagination.current_page <= 1"
        @click="fetchSessions(pagination.current_page - 1)"
      >
        &laquo; السابق
      </button>

      <button
        v-for="page in pagination.last_page"
        :key="page"
        :class="{ active: pagination.current_page === page }"
        @click="fetchSessions(page)"
      >
        {{ page }}
      </button>

      <button
        class="next"
        :disabled="pagination.current_page >= pagination.last_page"
        @click="fetchSessions(pagination.current_page + 1)"
      >
        التالي &raquo;
      </button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";

export default {
  name: "AddLessonGroup",
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      pagination: {},
      links: {},
      debounceTimer: null,
      searchQuery: "",
      start_time: "",
      end_time: "",
      is_new: 0,
      newLessonName: "",
      start_date: "",
      lesson: [],
      ayah: [],
      teatcher_value: null,
      teatcherOptions: [],
      lessontype_value: null,
      lessontypeOptions: [],
      ayato_value: null,
      ayato_value_new: null,
      ayatoOptions: [],
      ayatoOptionsNew: [],
      ayafrom_value: null,
      ayafrom_value_new: null,
      ayafromOptions: [],
      ayafromOptionsNew: [],
      namelesson_value: null,
      namelessonOptions: [],
      surah_value: null,
      surah_value_new: null,
      surahOptions: [],
      surahOptionsNew: [],
      stage_value: null,
      stage_value_new: null,
      stageOptions: [],
      stageOptionsNew: [],
      lessonTable: [], // لتخزين البيانات المعروضة في الجدول
      sortKey: "",
      sortOrder: 1,
      selectedRowIndex: null, // لتمثيل الصف المحدد
      newPosition: null, // لتمثيل الرقم الجديد للصف
      LessonsGroup: [],
      isEditing: false,
      editingSessionId: null,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    sortedLessons() {
      if (!this.sortKey) return this.lessonTable;
      return this.lessonTable.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        if (aValue < bValue) return -1 * this.sortOrder;
        if (aValue > bValue) return 1 * this.sortOrder;
        return 0;
      });
    },
    filteredAyatoOptions() {
      if (this.ayafrom_value_new) {
        return this.ayatoOptionsNew.filter(
          (option) => option.id !== this.ayafrom_value_new.id
        );
      }
      return this.ayatoOptionsNew;
    },
  },
  methods: {
    async getTypeLesson() {
      try {
        const response = await axios.post("organization_fetch_session_types ");
        this.lessontypeOptions = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getLessonsGroup() {
      try {
        const response = await axios.post("organization_fetch_all_sessions", {
          group_id: this.$route.params.groupId,
          course_id: this.$route.params.courseId,
        });
        this.LessonsGroup = response.data.data;
        console.log(this.LessonsGroup);
      } catch (error) {
        console.log(error);
      }
    },
    // clearInputs() {
    //   this.lessonTitle = "";
    //   this.lessonStage = "";
    //   this.lessonStageNew = "";
    //   this.lessonSurah = "";
    //   this.lessonSurahNew = "";
    //   this.lessonAyah = "";
    //   this.lessonAyahNew = "";
    //   this.start_time = "";
    //   this.end_time = "";
    //   this.start_date = "";
    //   this.teatcher_value = "";
    //   this.lessontype_value = "";
    // },

    async reorderRow(id) {
      if (!id || typeof id !== "number") {
        console.error("لم يتم تحديد معرف صالح للصف.");
        return;
      }
      if (!id) {
        console.error("لم يتم تحديد صف.");
        return;
      }
      try {
        const res = await axios.post("sort_group_stage_session", {
          order_by: this.newPosition,
          id: id,
        });
        if (res.data.status === true) {
          console.log(`تم إعادة ترتيب الصف بمعرف ${id}`);
          this.fetchSessions(); // تحديث البيانات بعد الطلب
        } else {
          console.error(res.data.message); // معالجة الخطأ إذا فشل الطلب
        }
      } catch (error) {
        console.error("Error while reordering row:", error); // معالجة الأخطاء
      }
    },

    selectRow(index) {
      this.selectedRowIndex = index;
      const selectedLesson = this.lessonTable[index];
      if (selectedLesson && selectedLesson.id) {
        this.newPosition = index + 1;
      } else {
        console.error("الصف المحدد لا يحتوي على معرف صالح.");
      }
    },

    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder = -this.sortOrder;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    formatTime(time) {
      if (!time) return ""; // التحقق من وجود الوقت
      const [hours, minutes] = time.split(":").map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // تحويل 0 إلى 12 في صيغة 12 ساعة
      return `${formattedHours}:${String(minutes).padStart(2, "0")} ${suffix}`;
    },

    async getLessons() {
      const response = await axios.post(
        "organization_fetch_main_sessions_for_session",
        {
          group_id: this.$route.params.groupId,
          course_id: this.$route.params.courseId,
        }
      );

      if (response.data.status === true) {
        if (Array.isArray(response.data.data)) {
          this.lesson = response.data.data;

          this.namelessonOptions = this.lesson.map((item) => ({
            id: item.id,
            title: item.title,
          }));
        } else {
          console.error("Expected an array but got:", response.data.data);
        }
      } else {
        console.error("API response indicates failure.", response.data);
      }
    },
    async getSurah() {
      if (this.namelesson_value) {
        const response = await axios.post(
          "organization_fetch_surah_by_session",
          {
            session_id: this.namelesson_value.id,
          }
        );

        if (response.data.status === true) {
          this.surahOptions = response.data.data;
          this.surah_value = this.surahOptions[0]?.title;
          this.getAyahFrom();
          this.getAyahTo();
          this.getStages();

          // بعد اختيار الحلقة، قم بإزالتها من قائمة الخيارات
          // this.removeSelectedLesson(this.namelesson_value.id);
        }
      }
    },
    async getSurahNew() {
      const response = await axios.post("organization_fetch_surah_by_session");

      if (response.data.status === true) {
        this.surahOptionsNew = response.data.data;
        this.surah_value_new = this.surahOptionsNew.map((item) => item.id);
      }
    },

    async getAyahFrom() {
      const response = await axios.post("organization_fetch_ayah_for_session", {
        ayah_id: this.surahOptions.map((item) => item.from_ayah_id),
      });
      if (response.data.status === true) {
        this.ayafromOptions = response.data.data;
        this.ayafrom_value = this.ayafromOptions[0]?.title;
      }
    },
    async getAyahFromNew() {
      const response = await axios.post("organization_fetch_ayah_by_surah", {
        surah_id: this.surah_value_new.id,
        is_new: 1,
      });
      if (response.data.status === true) {
        this.ayafromOptionsNew = response.data.data;
        this.ayafrom_value_new = this.ayafromOptionsNew.map((item) => item.id);
      }
      this.getAyahToNew();
    },
    async getAyahToNew() {
      const response = await axios.post("organization_fetch_ayah_by_surah", {
        surah_id: this.surah_value_new.id,
        is_new: 1,
      });
      if (response.data.status === true) {
        this.ayatoOptionsNew = response.data.data;
        this.ayato_value_new = this.ayatoOptionsNew.map((item) => item.id);
      }
    },
    async getAyahTo() {
      const response = await axios.post("organization_fetch_ayah_for_session", {
        ayah_id: this.surahOptions.map((item) => item.to_ayah_id),
      });
      if (response.data.status === true) {
        this.ayatoOptions = response.data.data;
        this.ayato_value = this.ayatoOptions[0]?.title;
      }
    },
    async getStages() {
      const response = await axios.post(
        "organization_fetch_main_session_stage ",
        {
          session_id: this.namelesson_value.id,
          course_id: this.$route.params.courseId,
          is_new: this.is_new,
        }
      );
      if (response.data.status === true) {
        this.stageOptions = response.data.data;
        this.stage_value = this.stageOptions[0]?.title;
      }
    },
    async getStagesNew() {
      const response = await axios.post(
        "organization_fetch_main_session_stage ",
        {
          course_id: this.$route.params.courseId,
          is_new: 1,
        }
      );
      if (response.data.status === true) {
        this.stageOptionsNew = response.data.data;
        this.stage_value_new = this.stageOptions.map((item) => item.id);
      }
    },
    async getTeacher() {
      const response = await axios.post("organization_fetch_teachers ");
      if (response.data.status === true) {
        this.teatcherOptions = response.data.data;
      }
    },
    async fetchSessions(page = 1) {
      try {
        const res = await axios.post("organization_fetch_sessions", {
          group_id: this.$route.params.groupId,
          page: page,
        });
        if (res.data.status === true) {
          this.lessonTable = res.data.data.data;
          this.pagination = res.data.data.meta;
          this.links = res.data.data.links;
        } else {
          Swal.fire({
            icon: "error",
            title: "فشل تحميل البيانات",
            text: res.data.message || "حدث خطأ أثناء جلب البيانات.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل تحميل البيانات",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },

    debounceSearch: debounce(function () {
      this.searchLessons();
    }, 700),
    async searchLessons() {
      try {
        const res = await axios.post("organization_fetch_sessions", {
          group_id: this.$route.params.groupId,
          word: this.searchQuery,
        });
        if (res.data.status === true) {
          this.lessonTable = res.data.data.data;
        } else {
          this.lessonTable = [];
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل البحث",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
        this.lessonTable = [];
      }
    },

    async submitForm() {
      try {
        const formData = {
          group_id: this.$route.params.groupId,
          session_id: this.namelesson_value?.id,
          date: this.start_date,
          teacher_id: this.teatcher_value?.id || null,
          session_type_id: this.lessontype_value?.id || null,
          stage_id:
            this.stage_value_new?.id || this.stageOptions[0]?.id || null,
          start_ayah_id:
            this.ayafrom_value_new?.id || this.ayafromOptions[0]?.id || null,
          end_ayah_id:
            this.ayato_value_new?.id || this.ayatoOptions[0]?.id || null,
          surah_id:
            this.surah_value_new?.id || this.surahOptions[0]?.id || null,
          is_new: this.is_new,
          start_time: this.start_time || "",
          end_time: this.end_time || "",
          title: this.newLessonName,
        };

        const addlesson = document.getElementById("addlesson");
        const editlesson = document.getElementById("editlesson");
        const save = document.getElementById("save");
        const edit = document.getElementById("edit");
        if (this.isEditing) {
          formData.id = this.editingSessionId;
          delete formData.session_id;
          addlesson.style.display = "block";
          editlesson.style.display = "none";
          save.style.display = "block";
          edit.style.display = "none";
        }

        const response = await axios.post(
          this.isEditing
            ? "organization_edit_session"
            : "organization_add_session",
          formData
        );

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم بنجاح",
            text: response.data.message,
            timer: 2000,
            showConfirmButton: false,
          });

          // استدعاء الدالة لجلب البيانات المحدثة
          await this.fetchSessions();

          // إعادة تعيين النموذج
          this.resetForm();
        } else {
          Swal.fire({
            icon: "error",
            title: "فشل اتمام العملية",
            text: response.data.message || "حدث خطأ أثناء العملية.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل اتمام العملية",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },

    resetForm() {
      this.is_new = 0;
      this.namelesson_value = null;
      this.newLessonName = "";
      this.start_date = "";
      this.teatcher_value = null;
      this.lessontype_value = null;
      this.ayato_value = null;
      this.ayafrom_value = null;
      this.stage_value = null;
      this.surah_value = null;
      this.start_time = null;
      this.end_time = null;
    },
    async DeletRow(id) {
      try {
        const result = await Swal.fire({
          title: "هل أنت متأكد من عملية المسح؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
          cancelButtonText: "إلغاء",
        });

        // إذا اختار المستخدم التأكيد
        if (result.isConfirmed) {
          const res = await axios.post("organization_delete_session", { id });

          if (res.data.status === true) {
            // إظهار رسالة النجاح
            await Swal.fire({
              icon: "success",
              title: "تم الحذف بنجاح",
              text: res.data.message,
              timer: 500,
              showConfirmButton: false,
            });
            this.getLessons();

            // تحديث البيانات بعد الحذف
            this.fetchSessions();
          } else {
            // إذا فشلت عملية الحذف
            Swal.fire({
              icon: "error",
              title: "فشل الحذف",
              text: res.data.message || "حدث خطأ أثناء عملية الحذف.",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل الحذف",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
    async updateRow(id) {
      const edit = document.getElementById("edit");
      const save = document.getElementById("save");
      const isnew = document.getElementById("isnew");
      const editlesson = document.getElementById("editlesson");
      const addlesson = document.getElementById("addlesson");
      this.isEditing = true;
      this.editingSessionId = id;
      try {
        const res = await axios.post("organization_fetch_session_details", {
          id,
        });

        if (res.data.status == true) {
          edit.style.display = "block";
          save.style.display = "none ";
          isnew.style.display = "none ";
          editlesson.style.display = "block ";
          addlesson.style.display = "none ";
          this.end_time = res.data.data.end_time;
          this.start_time = res.data.data.start_time;
          this.start_date = res.data.data.date;
          this.teatcher_value = res.data.data.teacher;
          this.lessontype_value = res.data.data.session_type;
          this.namelesson_value = res.data.data.session;
          this.ayafrom_value = res.data.data.start_ayah.title;
          this.ayato_value = res.data.data.end_ayah.title;
          this.surah_value = res.data.data.surah.title;
          this.stage_value = res.data.data.stage.title;
          this.is_new = res.data.data.is_new;
          this.newLessonName = res.data.data.title;
          this.stage_value_new = res.data.data.stage;
          this.surah_value_new = res.data.data.surah;
          this.ayafrom_value_new = res.data.data.start_ayah;
          this.ayato_value_new = res.data.data.end_ayah;

          // this.stage_value = res.data.data.
          // console.log(this.end_time);
        } else {
          Swal.fire({
            icon: "error",
            title: "خطاء",
            text: res.data.message || "حدث خطاء في جلب البيانات.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطاء في جلب البيانات.";
        Swal.fire({
          icon: "error",
          title: "خطاء",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  },
  watch: {
    // is_new(newValue) {
    //   if (newValue) {
    //     this.clearInputs();
    //   }
    // },
  },
  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    this.getLessons();
    this.getTeacher();
    this.fetchSessions();
    this.getSurahNew();
    this.getStagesNew();
    this.getLessonsGroup();
    this.getTypeLesson();
  },
};
</script>
<style scoped lang="scss">
.add-lesson {
  padding: 2rem;
}
.all-btn {
  margin-top: 2rem;
}
.lesson {
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;

  border: 1px solid var(--main);
}
.head h4 {
  font-family: "bold";
}
th.sorted {
  background-color: #f0f0f0;
}

th.sorted i {
  margin-left: 5px;
}

th.sorted i.fa-arrow-up {
  color: green;
}

th.sorted i.fa-arrow-down {
  color: red;
}
.selected {
  background-color: #e9e5e5;
}
button.save {
  display: block;
  margin-inline-start: auto;
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;
  width: 25%;
  border: 1px solid var(--main);
}
.table > :not(caption) > * > * {
  background-color: unset !important;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.multiselect--disabled {
  background: unset !important;
  opacity: unset !important;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: unset !important;
  color: unset !important;
}
i.fa-solid.fa-trash {
  color: red;
}
.allde {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allde p {
  font-family: "regular";
}
.allde label {
  padding: 0;
  margin: 0;
}
.search-container {
  margin: 1rem 0;
}
.search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.not-found {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
  margin-top: 1rem;
}
.replace {
  background-color: var(--main);
  font-family: "regular";
  padding: 0.5rem;
  border-radius: 5px;
  color: var(--secondary);
  border: 0;
  margin-right: 10px;
}
.sqdwel {
  border: 1px solid var(--main);
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main);
      color: #fff;
    }

    &.active {
      background-color: var(--main);
      color: #fff;
      font-weight: bold;
    }

    &:disabled {
      background-color: #eee;
      color: #aaa;
      cursor: not-allowed;
      font-family: "regular";
    }

    &.prev,
    &.next {
      font-size: 16px;
      font-family: "regular";
    }

    &:not(.active) {
      background-color: #f5f5f5;
      color: #333;
    }
  }
}
.row-options {
  margin-bottom: 0.5rem;
}

.all-action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}
</style>
