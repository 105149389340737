<template>
  <div class="col-lg-6 col-md-12 col-12">
    <div class="low-lesson">
      <div class="crad-lesson">
        <div class="img-lesson">
          <img src="@/assets/mida/vid.png" class="" alt="" />
        </div>
        <div class="parnt-card-lesson">
          <div class="text-card-lesson">
            <p>{{ item.session_type }}</p>
            <h3>
              {{ item.title }}
            </h3>
            <span>{{ item.group_name }}</span>
          </div>
          <div class="lasts">
            <div class="las">
              <!-- <div class="lesson-last-card">
                <img src="@/assets/mida/small pic.png" alt="" />
                <h3>الشيخ:أحمد عبد الملك</h3>
              </div> -->
              <div class="lesson-last-1-card">
                <i class="fa-regular fa-clock"></i>
                <h6>وقت البدء: {{ item.start_time }} : {{ item.date }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style></style>
