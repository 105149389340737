import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useAppparentStore = defineStore("appparent", {
  state: () => ({
    appparent: [],
  }),
  actions: {
    async AddAppparent(appperntData) {
      const formData = new FormData();

      // If an image file exists, append it
      if (appperntData.imageFile) {
        console.log("Appending image:", appperntData.imageFile);  // Log the image file
        formData.append("image", appperntData.imageFile);
      }

      // Log other form data
      Object.keys(appperntData).forEach((key) => {
        if (key !== "imageFile" && key !== "image" && key !== "certificateFile") {
          if (appperntData[key] !== null && appperntData[key] !== undefined && appperntData[key] !== "") {
            formData.append(key, appperntData[key]);
          }
        }
      });


      try {
        const response = await axios.post("store_application_info", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Content type must be multipart/form-data for file uploads
          },
        });

        if (response.data.status === true) {
          this.appparent = response.data.data;
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة البيانات بنجاح",
          });
          router.push("/");
          console.log(this.appparent, "appparent data updated");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
          console.error("Error fetching appparent.");
        }
      } catch (error) {
        console.error("❌ خطأ أثناء الإرسال:", error);

        let errorMessage = "حدث خطأ أثناء الإرسال. حاول مرة أخرى.";

        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: errorMessage
        });
      }
    },

    async fetchAppparent() {
      try {
        const response = await axios.post("fetch_application_info", {
          type: 3,
        });
        if (response.data.status === true) {
          this.appparent = response.data.data;
          console.log(this.appparent, "appparent data updated");
        } else {
          console.error("Error fetching appparent.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
});
