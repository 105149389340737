<template>
  <div class="employees">
    <HeadersPages
      title="الطلاب"
      button="+ اضافة طالب"
      link="/add-student"
      icon="fa-solid fa-child"
      v-if="create"
    />
    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن طالب..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        @delete="handleDeleteEmployee"
        :deletes="deletes"
        :ismaster="ismaster"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useStudentsStore } from "@/stores/student/studentindex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { debounce } from "lodash"; // استيراد دالة debounce

import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: [
        "ID",
        "الصور",
        "اسم الطالب",
        "البريد الالكتروني",
        "رقم الهاتف",
      ],
      editLink: "/edit-student",
      viewLink: "/view-student",
    };
  },
  computed: {
    ...mapState(useStudentsStore, {
      students: (state) => state.students,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.students, "ssss");
      const dataToDisplay = this.students;

      return dataToDisplay.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const StudentsStore = useStudentsStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await StudentsStore.fetchStudents(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await StudentsStore.fetchStudents(1, this.word);

      if (StudentsStore.students.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },

    handlePageChange(page) {
      const StudentsStore = useStudentsStore();
      StudentsStore.fetchStudents(page);
    },
    async handleDeleteEmployee(id) {
      const studentsStore = useStudentsStore();
      console.log(id);

      await studentsStore.deleteStudent(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const studentsStore = useStudentsStore();
    await studentsStore.fetchStudents();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped></style>
