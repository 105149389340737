<template>
  <div class="courses">
    <header-pages
      title="الارقام"
      button="+ إضافة رقم "
      link="/add-counter"
      icon="fa-solid fa-globe"
      v-if="create"
    />
    <div class="alll">
      <!-- <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن رقم..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div> -->
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="handleDeleteGroup"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

      <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { useCountStore } from "@/stores/CounterHeader/CountStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  components: { HeaderPages, TablesPageVue, PaginationPage },

  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "الرقم", "الوصف"],
      editLink: "/edit-counter",
      viewLink: "/view-counter",
    };
  },

  computed: {
    ...mapState(useCountStore, {
      counter: (state) => state.counter,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      if (!Array.isArray(this.counter)) {
        console.warn("Counter is not an array:", this.counter);
        return [];
      }

      return this.counter.map((coun) => [coun.id, coun.sub_title, coun.title]);
    },

    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },

  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const countStore = useCountStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await countStore.getCount(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await countStore.getCount(1, this.word);

      if (countStore.counter.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    handlePageChange(page) {
      const CountStore = useCountStore();
      CountStore.getCount(page);
    },
    handlePageChange(page) {
      const paginationStore = usePaginationStore();
      paginationStore.setCurrentPage(page);
    },

    handleDeleteGroup(id) {
      const counterStore = useCountStore();
      counterStore.deleteCounter(id);
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const counterStore = useCountStore();
    counterStore.getCount();

    this.debouncedSearch = debounce(() => {
      this.handleSearch();
    }, 700);
  },
};
</script>

<style scoped></style>
