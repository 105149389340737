<template>
  <div class="main-live">
    <div class="user">
      <div class="heading">
        <h4>كل الطلاب ({{ users.length }})</h4>
      </div>
      <ul>
        <li>
          <div class="profile" v-for="user in users" :key="user.user_id">
            <div class="img-name">
              <img :src="user.user_image" alt="" />

              <h4>{{ user.user_name }}</h4>
            </div>
            <div class="drop">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <ul class="dropdown-menu">
                  <li class="dropdown-item" @click="openRate(user.user_id)">
                    تقييم الطالب
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">ارسال رسالة لولى الأمر</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">ارسال رسالة للطالب</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="rate" id="rate">
      <span @click="closeRate">X</span>
      <div class="heading">
        <h4>اضافة تقييم للطالب</h4>
      </div>
      <p>هل تريد عرض التقييم لسابق للطالب ؟</p>
      <button @click="openOldRate('previous', 'specificStudentId')">نعم</button>
      <button @click="openOldRates('new', 'specificStudentId')">
        تقييم جديد
      </button>
    </div>
    <div class="old_rate" id="old_rate">
      <div class="heading">
        <h4>{{ rateTitle }}</h4>
      </div>
      <div class="select">
        <label for="">كيف كانت درجة استيعاب الطالب ؟</label>
        <multiselect
          v-model="rate"
          :options="ratesoptions"
          :searchable="false"
          label="title"
          track-by="id"
          :multiple="false"
          placeholder="أختر التقيم"
        ></multiselect>
        <label for="">ملاحظات</label>
        <textarea name="" id="" v-model="s_understanding_comment"></textarea>
        <label for="">تقييم الاداء </label>
        <multiselect
          v-model="Performance_evaluation"
          :options="Performance_evaluationoptions"
          :searchable="false"
          label="title"
          track-by="id"
          :multiple="false"
          placeholder="أختر التقيم"
        ></multiselect>
        <label for="">ملاحظات</label>
        <textarea name="" id="" v-model="s_performance_comment"></textarea>
      </div>
      <button v-if="rateTitle === 'تقييم جديد'" @click="addNewRate">
        اضافة تقييم جديد
      </button>
      <button v-if="rateTitle === 'التقييم السابق'" @click="addNewRate">
        تعديل التقيم
      </button>

      <button @click="stepBack">رجوع</button>
    </div>

    <div class="live-camera" id="camera">
      <div class="leave-button" v-if="joined">
        <button @click="leaveMeeting">
          الخروج من الصفحه نهائيا
          <i class="fa-solid fa-right-from-bracket"></i>
        </button>
      </div>

      <iframe
        id="iframe"
        title="100ms-app"
        allow="camera *;microphone *;display-capture *"
        :src="`https://crazy-livestream-1045.app.100ms.live/meeting/${this.$route.query.host_code}`"
        style="width: 100%; border: 0; border-radius: 10px"
        @load="onJoin"
      ></iframe>

      <!-- إظهار زر الخروج فقط عندما يكون المستخدم قد انضم -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  data() {
    return {
      rateTitle: "التقييم السابق",
      hostcode: this.$route.query.host_code || "",
      session_id: this.$route.params.session_id || "",
      joined: false,
      s_understanding_comment: "",
      s_performance_comment: "",
      student: [],
      users: [],
      user_id: "",
      rate: null,
      ratesoptions: [
        { id: 0, title: "ممتاز" },
        { id: 1, title: "جيد" },
        { id: 2, title: "متوسط" },
        { id: 3, title: "سيء" },
      ],
      Performance_evaluation: null,
      Performance_evaluationoptions: [
        { id: 0, title: "ممتاز" },
        { id: 1, title: "جيد" },
        { id: 2, title: "متوسط" },
        { id: 3, title: "سيء" },
      ],
    };
  },
  mounted() {
    if (this.hostcode && this.session_id) {
      const { pathname } = window.location;
      window.history.replaceState({}, document.title, pathname);
    }
  },
  components: {
    Multiselect,
  },
  methods: {
    async getFristSelect() {
      const res = await axios.post("student_understanding");
      if (res.data.status === true) {
        this.ratesoptions = res.data.data;
      }
    },
    closeRate() {
      const rateElement = document.getElementById("rate");

      if (window.innerWidth <= 425) {
        rateElement.style.width = "100%";
      } else {
        rateElement.style.width = "0%";
      }

      rateElement.style.right = "-100%";
    },

    openRate(id) {
      const rateElement = document.getElementById("rate");

      // تحديد العرض بناءً على عرض الشاشة
      if (window.innerWidth <= 425) {
        // إذا كانت الشاشة صغيرة
        rateElement.style.width = "100%";
      } else {
        rateElement.style.width = "30%";
      }

      rateElement.style.right = "0%";
      this.user_id = id;
      console.log(this.user_id, "asdasdasdsadsa");
    },
    async openOldRate(type) {
      const rateElements = document.getElementById("old_rate");

      // تحديد العرض بناءً على عرض الشاشة
      if (window.innerWidth <= 425) {
        // إذا كانت الشاشة صغيرة
        rateElements.style.width = "100%";
      } else {
        rateElements.style.width = "30%";
      }

      rateElements.style.right = "0%";

      // تعيين العنوان بناءً على نوع التقييم
      if (type === "previous") {
        this.rateTitle = "التقييم السابق";
      } else if (type === "new") {
        this.rateTitle = "تقييم جديد";
      }

      try {
        // إرسال طلب للحصول على التفاصيل
        const res = await axios.post("fetch_rate_details", {
          session_id: this.session_id,
          user_id: this.user_id,
        });

        // التحقق من حالة الاستجابة
        if (res.data.status === true) {
          // تعيين قيمة `this.rate` بناءً على التقييم السابق
          this.ratesoptions.forEach((rate) => {
            if (rate.id == res.data.data.student_understanding) {
              this.rate = rate; // تعيين الـ id فقط
            }
          });

          // تعيين قيمة `Performance_evaluation` بناءً على التقييم الخاص بالأداء
          this.Performance_evaluationoptions.forEach((rate) => {
            if (rate.id == res.data.data.student_performance) {
              this.Performance_evaluation = rate; // تعيين الـ id فقط
            }
          });

          // تعيين التعليقات
          this.s_understanding_comment = res.data.data.s_understanding_comment;
          this.s_performance_comment = res.data.data.s_performance_comment;
        } else {
          console.log(res.data.message);
        }
      } catch (error) {
        console.error("Error occurred during the API call:", error);
      }
    },

    async openOldRates(type) {
      // Open the old rate section
      (this.s_understanding_comment = ""),
        (this.s_performance_comment = ""),
        (this.rate = ""),
        (this.Performance_evaluation = ""),
        (document.getElementById("old_rate").style.width = "30%");
      const rateElements = document.getElementById("old_rate");

      // تحديد العرض بناءً على عرض الشاشة
      if (window.innerWidth <= 425) {
        // إذا كانت الشاشة صغيرة
        rateElements.style.width = "100%";
      } else {
        rateElements.style.width = "30%";
      }

      rateElements.style.right = "0%";

      // Set the title based on the button clicked
      this.rateTitle = type === "previous" ? "التقييم السابق" : "تقييم جديد";
    },

    async addNewRate() {
      try {
        const res = await axios.post("add_rate", {
          session_id: this.session_id,
          user_id: this.user_id,
          student_understanding: this.rate.id,
          student_performance: this.Performance_evaluation.id,
          s_understanding_comment: this.s_understanding_comment,
          s_performance_comment: this.s_performance_comment,
        });

        // Handle the response
        if (res.data.status === true) {
          console.log("Request successful");
          this.s_understanding_comment = "";
          this.s_performance_comment = "";
          this.rate = "";
          this.Performance_evaluation = "";
          document.getElementById("old_rate").style.width = "0%";
          document.getElementById("old_rate").style.right = "-100%";
          Swal.fire("تم بنجاح", "تم بنجاح", "success");
        } else {
          Swal.fire("خطاء", "خطاء", "error");
        }
      } catch (error) {
        Swal.fire("خطاء", "خطاء", "error");
      }
    },

    stepBack() {
      document.getElementById("old_rate").style.width = "0%";
      document.getElementById("old_rate").style.right = "-100%";
    },

    onJoin() {
      setTimeout(() => {
        this.joined = true;
      }, 3500);
    },
    async leaveMeeting() {
      const response = await axios.post("organization_leave", {
        session_id: this.session_id,
      });
      if (response.data.status === true) {
        this.$router.push("/");
        Swal.fire("تم الخروج", "تم الخروج", "success");
      } else {
        console.log("erorr");
        Swal.fire("خطاء", "خطاء", "error");
      }
    },
    async fetchstudent() {
      const resstu = await axios.post("organization_attendance", {
        session_id: this.session_id,
      });
      if (resstu.data.status === true) {
        this.student = resstu.data.data;
      } else {
        console.log(resstu.data.message);
      }
    },
    async fetchusers() {
      try {
        const response = await axios.post("fetch_session_attendance", {
          session_id: this.session_id,
        });

        if (response.data.status === true) {
          this.users = response.data.data;
          this.user_id = this.users.map((user) => user.user_id);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
  },
  mounted() {
    this.fetchstudent();
    this.fetchusers();
  },
};
</script>
<style scoped lang="scss">
button.hms-ui-c-iKBXPV.hms-ui-c-bIwPpW {
  display: none !important;
}
.hms-ui-c-bIwPpW {
  display: none !important;
}
button.hms-ui-c-iKBXPV.hms-ui-c-bIwPpW.hms-ui-c-iKBXPV-ijpWFml-css {
  display: none !important;
}
</style>
