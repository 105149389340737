<template>
  <div class="courses">
    <header-pages
      button="+ إضافه صلاحيه"
      link="/add-permission"
      title="الصلاحيات"
      icon="fa-solid fa-thumbtack"
      v-if="create"
    />

    <div class="alll">
      <tables-page-vue
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        :headers="tableHeaders"
        :pages="tablePages"
        :rows="tableRows"
        :showSelect="false"
        @delete="handleDeletePermission"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { usePermissionStore } from "@/stores/permission/permissionStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";

export default {
  components: { HeaderPages, TablesPageVue, PaginationPage },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "	الصلاحيات"],
      editLink: "edit-permission",
      viewLink: "view-permission",
    };
  },

  computed: {
    ...mapState(usePermissionStore, {
      permissions: (state) => state.permissions,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      return this.permissions.map((part) => [
        part.id,
        part.name ?? "غير موجود",
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const Stored = usePermissionStore();
      Stored.getPermissions(page);
    },
    handleDeletePermission(id) {
      const permissionStore = usePermissionStore();
      permissionStore.deletePermission(id);
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const subscriptionStore = usePermissionStore();
    subscriptionStore.getPermissions();
  },
};
</script>

<style></style>
