<template>
  <div
    class="tab-pane fade"
    id="v-pills-password"
    role="tabpanel"
    aria-labelledby="v-pills-password-tab"
  >
    <div class="head-tab">
      <h6>تغير كلمه المرور</h6>
    </div>
    <div class="col-lg-7 col-md-10 col-10">
      <form class="password" @submit.prevent="changePassword">
        <div class="form-group">
          <!-- <input type="tel" class="form-control" placeholder="رقم الجوال" />
          <i class="fa-solid fa-phone"></i> -->

          <input
            type="password"
            class="form-control"
            placeholder="كلمة المرور القديمة"
            v-model="oldPassword"
          />
          <!-- <i class="fa-solid fa-lock lo"></i> -->

          <input
            type="password"
            class="form-control"
            placeholder="كلمة المرور الجديدة"
            v-model="newPassword"
          />
          <!-- <i class="fa-solid fa-lock-open set"></i> -->
        </div>
        <button class="btn btn-success">حفظ</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
    };
  },
  methods: {
    changePassword() {
      const formData = new FormData();
      formData.append("old_password", this.oldPassword);
      formData.append("new_password", this.newPassword);
      axios
        .post("teacher_account_settings_change_password", formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
