<template>
  <div class="col-lg-4 col-md-4 col-12 mt-4">
    <RouterLink
      :to="`new-testament-collection/${group.id}/${group.course_id}`"
      class="card-group d-flex gap-2 flex-column align-items-center justify-content-center"
    >
      <!-- <img
        class="img-group w-75 rounded"
        src="https://imgur.com/nxxE5AZ.png"
        alt=""
      /> -->
      <h5>{{ group.title }}</h5>
    </RouterLink>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
    },
  },
};
</script>
