import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";

export const usePermissionStore = defineStore("permission", {
  state: () => ({
    permissions: [],
  }),
  actions: {
    async getPermissions() {
      try {
        const response = await axios.post(`fetch_roles`);
        this.permissions = response.data.data;
        // console.log(this.permissions);
        
      } catch (error) {
        console.log("Error fetching permissions:", error);
      }
    },
    async deletePermission(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_role", { id });
          this.permissions = this.permissions.filter(
            (permissions) => permissions.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف الصلاحية", "success");
        }
      } catch (error) {
        console.error("Error deleting permissions:", error);
        Swal.fire(
          "Error!",
          "There was an error deleting the subscriptions.",
          "error"
        );
      }
    },
  },
});
