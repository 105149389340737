<template>
  <div class="Categories">
    <HeadersPages
      title=" الاقسام"
      button="+ اضافة  قسم"
      link="/add-category"
      icon="fa-solid fa-globe"
      v-if="create"
    />
    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="edit ? editLink : ''"
        :viewLink="read ? viewLink : ''"
        :deletes="deletes"
        @delete="deleteCategory"
        :ismaster="ismaster"
      />
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useCategoryStore } from "@/stores/category/categoryStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "CategoriesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      tableHeaders: ["ID", "اسم "],
      editLink: "/edit-category",
      viewLink: "/view-category",
    };
  },
  computed: {
    ...mapState(useCategoryStore, {
      Category: (state) => state.Category,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.category, "ssss");
      return this.Category.map((emp) => [emp.id, emp.title]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const categoryStore = useCategoryStore();
      categoryStore.fetchCategory(page);
    },
    async deleteCategory(id) {
      const categoryStore = useCategoryStore();
      console.log(id);

      await categoryStore.deleteCategory(id);
    },
  },

  async mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    const categoryStore = useCategoryStore();
    await categoryStore.fetchCategory();
  },
};
</script>
