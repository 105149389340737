<template>
  <section class="index_cards">
    <div class="content-main-lib">
      <section class="container">
        <div class="head-reports">
          <h4>تقارير الطالب /التقييمات</h4>
        </div>
        <div class="content-reports">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-12 col-12">
                <form class="form-group">
                  <input
                    class="form-control teacher"
                    type="search"
                    placeholder="ابحث بإسم الدرس ...."
                    aria-label="Search"
                    v-model="search"
                  />
                </form>
              </div>
            </div>
            <div class="table-responsive" dir="rtl">
              <table class="table table-striped">
                <caption class="visually-hidden">
                  سجل التقييمات الأكاديمية والسلوكية
                </caption>
                <thead>
                  <tr>
                    <th scope="col" class="w-25">الدرس</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">الدرجة السلوكية</th>
                    <th scope="col">التقييم السلوكي</th>
                    <th scope="col">الدرجة الأكاديمية</th>
                    <th scope="col">التقييم الأكاديمي</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <th scope="row">
                      {{ item.session_title }}
                    </th>
                    <td>{{ item.date }}</td>
                    <td>
                      <span class="badge bg-danger">{{
                        item.student_performance_level
                      }}</span>
                    </td>
                    <td>
                      <span class="text-danger">
                        <i class="bi bi-exclamation-triangle-fill"></i>
                        {{ item.student_performance_comment }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-danger">{{
                        item.student_understanding_level
                      }}</span>
                    </td>
                    <td>
                      <span class="text-warning">
                        <i class="bi bi-person-exclamation"></i>
                        {{ item.student_understanding_comment }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  data() {
    return {
      data: [],
      search: "",
    };
  },
  methods: {
    getDataRating() {
      axios
        .post("teacher_student_ratings", {
          user_id: this.$route.params.id,
          word: this.search,
        })
        .then((res) => {
          this.data = res.data.data;
          console.log(this.data);
        });
    },
    debouncedSearch: debounce(function () {
      this.getDataRating();
    }, 500), // 500ms delay
  },

  mounted() {
    this.getDataRating();
  },
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
};
</script>

<style></style>
