<template>
  <div class="counter_sec">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/note-1.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">اجمالى الاختبارت</h4>
            <p class="counter fw-bold" data-target="80">
              {{ data.exam_count }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/note-3.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">الاختبارات المنجزة</h4>
            <p class="counter fw-bold" data-target="20">
              {{ data.completed_exam_count }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/cont88.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">الاختبارات الغير منجزة</h4>
            <p class="counter fw-bold" data-target="56">
              {{ data.unfinished_exam_count }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="note">
          <img src="@/assets/mida/cehhh.png" alt="" />
          <div class="cardachivement">
            <h4 class="fw-semibold">اجمالي الاجابات</h4>
            <p class="counter fw-bold" data-target="360">
              {{ data.answer_count }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style></style>
