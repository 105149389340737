<template>
  <section class="index_cards">
    <!-- ======= Start navbar ======= -->

    <div class="content-main">
      <section class="container"></section>
      <div class="all">
        <section class="container">
          <div class="main-quran">
            <div class="row">
              <div class="col-lg-8 col-md-4 col-12">
                <h4>كل الدروس ( {{ lessonsEnd?.length }} )</h4>
              </div>
              <!-- <div class="col-lg-2 col-md-4 col-12">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    جميع المجموعات
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="Next-lessons.html">0</a>
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- <div class="col-lg-2 col-md-4 col-12">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    الدروس المنتهية
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="next-lesson.html"
                        >الدروس القادمه</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="lesson-today.html"
                        >الدروس اليوم</a
                      >
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>
            <div class="lesson">
              <div class="row">
                <lessonEndCard
                  v-for="lesson in lessonsEnd"
                  :key="lesson"
                  :item="lesson"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import lessonEndCard from "@/components/electronicReader/LessonEndCard.vue";
import axios from "axios";

export default {
  data() {
    return { lessonsEnd: [] };
  },
  components: { lessonEndCard },
  methods: {
    getLessons() {
      axios.post("teacher_finished_session ").then((res) => {
        this.lessonsEnd = res.data.data;
        // console.log(res.data.data);
      });
    },
  },
  mounted() {
    this.getLessons();
  },
};
</script>

<style></style>
