<template>
  <section class="index_cards">
    <!-- ======= Start navbar ======= -->

    <div class="content-main-lib">
      <section class="container">
        <div class="container">
          <div class="head-achieve">
            <h4>تقارير الطالب / الواجبات</h4>
            <!-- <div class="button-achieve">
                <button type="button" class="btn achieve">طباعة تقرير</button>
              </div> -->
          </div>
          <Counter :data="data" />

          <div class="content-achieve">
            <div class="row">
              <div class="col-lg-8 col-md-12 col-12">
                <form class="form-group">
                  <!-- <i class="fa-solid fa-magnifying-glass teacher"></i> -->
                  <input
                    class="form-control"
                    type="search"
                    placeholder="ابحث بإسم الدرس ...."
                    aria-label="Search"
                    v-model="search"
                  />
                </form>
              </div>
              <!-- <div class="col-lg-4 col-md-12 col-12">
                  <div class="celender">
                    <div class="form-group">
                      <input
                        class="form-control teacher"
                        type="text"
                        placeholder="12 مايو - 12 يوليو"
                        onfocus="(this.type='date')"
                        onblur="(this.type='text')"
                      />
                      <i class="fa-solid fa-calendar-day"></i>
                    </div>
                  </div>
                </div> -->
            </div>
            <Table :data="data" />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Counter from "@/components/exams/counter.vue";
import Table from "@/components/exams/table.vue";
import axios from "axios";
import { debounce } from "lodash";

export default {
  data() {
    return {
      data: [],
      search: "",
    };
  },
  methods: {
    getData() {
      axios
        .post("teacher_student_exams", {
          user_id: this.$route.params.id,
          word: this.search,
        })
        .then((res) => {
          this.data = res.data.data;
        });
    },
    debouncedSearch: debounce(function () {
      this.getData();
    }, 500), // 500ms delay
  },
  mounted() {
    this.getData();
  },
  components: {
    Counter,
    Table,
  },
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
};
</script>
