<template>
  <div class="col-lg-12 col-md-12 col-12">
    <div class="bg-white p-2">
      <div class="new-hrder my-4 px-4">
        <h4>بما تحب ان تبدأ ؟</h4>
      </div>
      <div
        class="main-testament d-flex justify-content-around flex-wrap gap-3 align-items-center"
      >
        <RouterLink :to="`/lesson-groups/${$route.params.id}`">
          <img src="@/assets/mida/s1.png" class="w-100" alt="" />
        </RouterLink>
        <RouterLink :to="`/student-groups/${$route.params.id}`">
          <img src="@/assets/mida/s2.png" class="w-100" alt="" />
        </RouterLink>
        <RouterLink class="d-flex align-items-center flex-column" :to="`/teacher/add-lesson-group/${$route.params.id}/${$route.params.courseId}`">
          <img src="@/assets/mida/s3.png" class="w-100" alt="" />
          <p class="my-2 fs-5">الحصص</p>
        </RouterLink>
        <RouterLink :to="`/group-exam/${$route.params.id}`">
          <img src="@/assets/mida/s4.png" class="w-100" alt="" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>
