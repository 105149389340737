import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth/AuthStore";
import Login from "@/views/dashboard/Auth/LoginPage.vue";
import Forget from "@/views/dashboard/Auth/ForgetPassword.vue";
import CodeReset from "@/views/dashboard/Auth/CodeReset.vue";
import Dashboard from "@/views/dashboard/dashboard.vue";
import HomeView from "@/views/dashboard/Home/HomeView.vue";
import HomePage from "@/views/dashboard/HomePage/HomePage.vue";
import EmployeesAdd from "@/views/dashboard/employees/EmployeesAdd.vue";
import EmployeesEdit from "@/views/dashboard/employees/EmployeesEdit.vue";
import EmployeesIndex from "@/views/dashboard/employees/EmployeesIndex.vue";
import GroupsIndex from "@/views/dashboard/groups/GroupsIndex.vue";
import groupsindexshow from "@/views/dashboard/groups/GroupsIndexShow.vue";
import GroupsView from "@/views/dashboard/groups/GroupView.vue";
import GroupsAdd from "@/views/dashboard/groups/GroupsAdd.vue";
import GroupsAdds from "@/views/dashboard/groups/GroupsAdds.vue";
import EditGroupsShow from "@/views/dashboard/groups/EditGroupsShow.vue";
import AddLessonGroup from "@/views/dashboard/groups/AddLessonGroup.vue";
import teacherAddLessonGroup from "@/views/dashboard/groups/AddTeacherLesson.vue";
import ShowLessonGroup from "@/views/dashboard/groups/ShowLessonGroup.vue";
import AddStudentGroup from "@/views/dashboard/groups/AddStudentGroup.vue";
import ShowStudentGroup from "@/views/dashboard/groups/ShowStudentGroup.vue";
import GroupsEdit from "@/views/dashboard/groups/GroupsEdit.vue";
import CoursesIndex from "@/views/dashboard/courses/CoursesIndex.vue";
import CoursesAdd from "@/views/dashboard/courses/CoursesAdd.vue";
import CoursesEdit from "@/views/dashboard/courses/CoursesEdit.vue";
import ExamsIndex from "@/views/dashboard/exams/ExamsIndex.vue";
import ExamsAdd from "@/views/dashboard/exams/ExamsAdd.vue";
import ExamsAddGroup from "@/views/dashboard/exams/ExamsAddGroup.vue";
import ExamsEditGroup from "@/views/dashboard/exams/ExamsGroupEdit.vue";
import AddQuestionExmas from "@/views/dashboard/exams/AddQuestionExmas.vue";
import ExamsEidtQuestion from "@/views/dashboard/exams/ExamsEidtQuestion.vue";
import ExamsShowGroup from "@/views/dashboard/exams/ExamsShowGroup.vue";
import ExamsShowStudent from "@/views/dashboard/exams/ExamsShowStudent.vue";
import QuestionsAddIndex from "@/views/dashboard/exams/QuestionsAddIndex.vue";
import ExamsEdit from "@/views/dashboard/exams/ExamsEdit.vue";
import ExamsGroupIndex from "@/views/dashboard/exams/ExamsGroup.vue";
import StudentIndex from "@/views/dashboard/student/StudentIndex.vue";
import AddStudentt from "@/views/dashboard/student/AddStudentt.vue";
import EditStudentt from "@/views/dashboard/student/EditStudent.vue";
import TeacherIndex from "@/views/dashboard/teacher/TeacherIndex.vue";
import ViewTeacher from "@/views/dashboard/teacher/TeacherView.vue";
import AddTeacher from "@/views/dashboard/teacher/AddTeacher.vue";
import IndexParent from "@/views/dashboard/parent/IndexParent.vue";
import EditParent from "@/views/dashboard/parent/editParent.vue";
import AddParent from "@/views/dashboard/parent/addParent.vue";
import EditTeacher from "@/views/dashboard/teacher/EditTeacher.vue";
import IndexModerators from "@/views/dashboard/moderators/IndexModerators.vue";
import AddModerators from "@/views/dashboard/moderators/AddModerators.vue";
import EditModerators from "@/views/dashboard/moderators/EditModerators.vue";
import IndexSlider from "@/views/dashboard/slider/IndexSlider.vue";
import EditSlider from "@/views/dashboard/slider/EditSlider.vue";
import AddSlider from "@/views/dashboard/slider/AddSlider.vue";
import WhoWe from "@/views/dashboard/whowe/WhoWeAdd.vue";
import IndexSuccesspartners from "@/views/dashboard/Successpartners/IndexSuccesspartners.vue";
import AddSuccesspartners from "@/views/dashboard/Successpartners/AddSuccesspartners.vue";
import EditSuccesspartners from "@/views/dashboard/Successpartners/EditSuccesspartners.vue";
import IndexServes from "@/views/dashboard/serves/IndexServes.vue";
import IndexFeaturesServes from "@/views/dashboard/featuresServes/index.vue";
import addFeaturesServes from "@/views/dashboard/featuresServes/add.vue";
import editFeaturesServes from "@/views/dashboard/featuresServes/edit.vue";
import Addserves from "@/views/dashboard/serves/Addserves.vue";
import EditServes from "@/views/dashboard/serves/EditServes.vue";
import AppTeacher from "@/views/dashboard/appteacher/AppTeacher.vue";
import Appparent from "@/views/dashboard/appparent/Appparent.vue";
import AppStudent from "@/views/dashboard/appstudent/AppStudent.vue";
import IndexBlog from "@/views/dashboard/blog/IndexBlog.vue";
import AddBlog from "@/views/dashboard/blog/AddBlog.vue";
import EditBlog from "@/views/dashboard/blog/EditBlog.vue";
import IndexDepartment from "@/views/dashboard/Department/IndexDepartment.vue";
import AddDepartment from "@/views/dashboard/Department/AddDepartment.vue";
import EditDepartment from "@/views/dashboard/Department/EditDepartment.vue";
import OpinionClient from "@/views/dashboard/opinionClient/index.vue";
import addOpinionClient from "@/views/dashboard/opinionClient/add.vue";
import editOpinionClient from "@/views/dashboard/opinionClient/edit.vue";
import IndexHashtag from "@/views/dashboard/Hashtag/IndexHashtag.vue";
import AddHashtag from "@/views/dashboard/Hashtag//AddHashtag.vue";
import EditHashtag from "@/views/dashboard/Hashtag/EditHashtag.vue";
import IndexComplaints from "@/views/dashboard/Complaints/IndexComplaints.vue";
import IndexCompetion from "@/views/dashboard/Competitions/CompetitionIndex.vue";
import AddCompetion from "@/views/dashboard/Competitions/CompetitionAdd.vue";
import EditCompetion from "@/views/dashboard/Competitions/CompetitionEdit.vue";
import CompetitionView from "@/views/dashboard/Competitions/CompetitionView.vue";
import Indexelectroniclibrary from "@/views/dashboard/electroniclibrary/IndexElectroniclibrary.vue";
import addElectroniclibrary from "@/views/dashboard/electroniclibrary/addElectroniclibrary.vue";
import editElectroniclibrary from "@/views/dashboard/electroniclibrary/editElectroniclibrary.vue";
import IndexCategory from "@/views/dashboard/category/IndexCategory.vue";
import AddCategory from "@/views/dashboard/category/addCategory.vue";
import EditCategory from "@/views/dashboard/category/editCategory.vue";
import QuestionBanksIndex from "@/views/dashboard/question_banks/QuestionBanksIndex.vue";
import AddQuestionBanks from "@/views/dashboard/question_banks/QuestionBanksAdd.vue";
import EditQuestionBanks from "@/views/dashboard/question_banks/QuestionBanksEdit.vue";
import IndexHeader from "@/views/dashboard/header/IndexHeader.vue";
import RelationIndex from "@/views/dashboard/Relations/RelationIndex.vue";
import RelationAdd from "@/views/dashboard/Relations/RelationAdd.vue";
import RelationEdit from "@/views/dashboard/Relations/RelationEdit.vue";
import AddHeader from "@/views/dashboard/header/AddHeader.vue";
import EditHeader from "@/views/dashboard/header/EditHeader.vue";
import WhoWeIndex from "@/views/dashboard/whowe/whoWeIndex.vue";
import WhoWeEdit from "@/views/dashboard/whowe/WhoWeEdit.vue";
import IndexCounter from "@/views/dashboard/Counter/IndexCounter.vue";
import AddCounter from "@/views/dashboard/Counter/AddCounter.vue";
import EditCounter from "@/views/dashboard/Counter/EditCounter.vue";
import IndexScreen from "@/views/dashboard/screens/IndexScreen.vue";
import AddScreen from "@/views/dashboard/screens/AddScreen.vue";
import EditScreen from "@/views/dashboard/screens/EditScreen.vue";
import IndexSubscription from "@/views/dashboard/subscription/subscriptionIndex.vue";
import AddSubscription from "@/views/dashboard/subscription/subscriptionAdd.vue";
import IndexPermission from "@/views/dashboard/permissions/IndexPermissions.vue";
import AddPermission from "@/views/dashboard/permissions/AddPermissions.vue";
import EditPermission from "@/views/dashboard/permissions/AddPermissions.vue";
import ExamResultIndex from "@/views/dashboard/ExamResults/ExamResultIndex.vue";
import ExamResultShow from "@/views/dashboard/ExamResults/ExamResultShow.vue";
import Competition from "@/views/dashboard/Competitions/CompetitionIndex.vue";
import live from "@/views/dashboard/live/liveTeacher.vue";
import Attendanceabsence from "@/views/dashboard/groups/Attendanceabsence.vue";
import Setting from "@/views/dashboard/setting/settingPage.vue";
import TeacherSetting from "@/views/dashboard/setting/TeacherSetting/ProfileSetting.vue";
import IndexJops from "@/views/dashboard/jops/IndexJops.vue";
import AddJops from "@/views/dashboard/jops/AddJops.vue";
import EditJops from "@/views/dashboard/jops/EditJops.vue";
import ResetPassword from "@/views/dashboard/Auth/ResetPassword.vue";
import TracherGroup from "@/views/dashboard/TracherGroup/index.vue";
import ElectronicReader from "@/views/dashboard/electronicReader/ElectronicReader.vue";
import LessonToday from "@/views/dashboard/electronicReader/LessonToday.vue";
import LessonNext from "@/views/dashboard/electronicReader/LessonNext.vue";
import TeacherExam from "@/views/dashboard/exams/TeacherExam.vue";
import TeacherExamAdd from "@/views/dashboard/exams/TeacherAddNewExam.vue";
import LessonEnd from "@/views/dashboard/electronicReader/LessonEnd.vue";
import TeacherGroups from "@/views/dashboard/groups/TeacherGroups.vue";
import NewTestamentCollection from "@/views/dashboard/groups/NewTestamentCollection.vue";
import GroupExam from "@/views/dashboard/groups/GroupExam.vue";
import LessonGroups from "@/views/dashboard/groups/lessonGroups.vue";
import StudentGroups from "@/views/dashboard/groups/StudentGroups.vue";
import StudentGroupsDetails from "@/views/dashboard/groups/StudentGroupsDetails.vue";
import StudentGroupsDetailsReport from "@/views/dashboard/groups/StudentGroupsDetailsReport.vue";
import StudentGroupsDetailsAchievements from "@/views/dashboard/groups/StudentGroupsDetailsAchievements.vue";
import StudentGroupsDetailsExams from "@/views/dashboard/groups/StudentGroupsDetailsExams.vue";
import StudentGroupsDetailsStudentEvaluations from "@/views/dashboard/groups/StudentGroupsDetailsStudentEvaluations.vue";
import lessons from "@/views/dashboard/lessons/lessons.vue";
import lessonsview from "@/views/dashboard/lessons/lessonsView.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/forget-password",
    name: "forget",
    component: Forget,
    meta: { guest: true },
  },
  {
    path: "/Code",
    name: "Code",
    component: CodeReset,
    meta: { guest: true },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/home-page",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/electronic-reader",
        name: "ElectronicReader",
        component: ElectronicReader,
      },
      {
        path: "/lesson-today",
        name: "LessonToday",
        component: LessonToday,
      },
      {
        path: "/lesson-next",
        name: "LessonNext",
        component: LessonNext,
      },
      {
        path: "/teacher-exams",
        name: "TeacherExam",
        component: TeacherExam,
      },
      {
        path: "/teacher-exams-add",
        name: "TeacherExamAdd",
        component: TeacherExamAdd,
      },
      {
        path: "/lesson-end",
        name: "LessonEnd",
        component: LessonEnd,
      },
      {
        path: "/teacher-groups",
        name: "TeacherGroups",
        component: TeacherGroups,
      },
      {
        path: "/new-testament-collection/:id/:courseId",
        name: "NewTestamentCollection",
        component: NewTestamentCollection,
      },
      {
        path: "/group-exam/:id",
        name: "GroupExam",
        component: GroupExam,
      },
      {
        path: "/lesson-groups/:id",
        name: "LessonGroups",
        component: LessonGroups,
      },
      {
        path: "/student-groups/:id",
        name: "StudentGroups",
        component: StudentGroups,
      },
      {
        path: "/student-groups-details/:id",
        name: "StudentGroupsDetails",
        component: StudentGroupsDetails,
      },
      {
        path: "/student-groups-details-report/:id",
        name: "StudentGroupsDetailsReport",
        component: StudentGroupsDetailsReport,
      },
      {
        path: "/student-groups-details-achievements/:id",
        name: "StudentGroupsDetailsAchievements",
        component: StudentGroupsDetailsAchievements,
      },
      {
        path: "/student-groups-details-exams/:id",
        name: "StudentGroupsDetailsExams",
        component: StudentGroupsDetailsExams,
      },
      {
        path: "/student-groups-details-student-evaluations/:id",
        name: "StudentGroupsDetailsStudentEvaluations",
        component: StudentGroupsDetailsStudentEvaluations,
      },
      {
        path: "/index-jops",
        name: "index-jops",
        component: IndexJops,
        meta: {
          module: "job_type",
        },
      },
      {
        path: "/add-jops",
        name: " add-jops",
        component: AddJops,
      },

      {
        path: "/edit-jops/:id",
        name: "edit-jops",
        component: EditJops,
      },
      {
        path: "/employees",
        name: "Employees",
        component: EmployeesIndex,
        meta: { module: "employee" },
      },
      {
        path: "/add-employee",
        name: "EmployeesAdd",
        component: EmployeesAdd,
      },
      {
        path: "/edit-employee/:id",
        name: "EmployeesEdit",
        component: EmployeesEdit,
      },
      {
        path: "/groups",
        name: "Groups",
        component: GroupsIndex,
      },
      {
        path: "/groups-index/:id",
        name: "Groupsshow",
        component: groupsindexshow,
        meta: { module: "groups" },
      },
      {
        path: "/groups-add",
        name: "Groupsadd",
        component: GroupsAdd,
      },
      {
        path: "/teacher-group",
        name: "TracherGroup",
        component: TracherGroup,
      },

      {
        path: "/add-group/:id",
        name: "GroupsAdd",
        component: GroupsAdds,
      },
      {
        path: "/edit-groups-show/:courseId/:groupId",
        name: "editgroupsshow",
        component: EditGroupsShow,
      },
      {
        path: "/add-lesson-group/:courseId/:groupId",
        name: "addlessongroup",
        component: AddLessonGroup,
        meta: {
          module: "organizations_sessions",
        },
      },
      {
        path: "/teacher/add-lesson-group/:groupId/:courseId",
        name: "teacherAddlessongroup",
        component: teacherAddLessonGroup,
      },
      {
        path: "/show-lesson-group/:courseId/:groupId",
        name: "showlessongroup",
        component: ShowLessonGroup,
      },
      {
        path: "/add-student-group/:courseId/:groupId",
        name: "addstudentgroup",
        component: AddStudentGroup,
        meta: {
          module: "organizations_group_student",
        },
      },
      {
        path: "/show-student-group/:courseId/:groupId",
        name: "showstudentgroup",
        component: ShowStudentGroup,
      },
      {
        path: "/view-group/:id",
        name: "GroupsView",
        component: GroupsView,
      },
      {
        path: "/teacher-setting",
        name: "TeacherSetting",
        component: TeacherSetting,
      },
      {
        path: "/edit-group/:id",
        name: "GroupsEdit",
        component: GroupsEdit,
      },
      {
        path: "/courses",
        name: "Courses",
        component: CoursesIndex,
        meta: {
          module: "courses",
        },
      },
      {
        path: "/add-course",
        name: "CoursesAdd",
        component: CoursesAdd,
      },
      {
        path: "/edit-courses/:id",
        name: "CoursesEdit",
        component: CoursesEdit,
      },
      {
        path: "/exams",
        name: "exams",
        component: ExamsIndex,
        meta: {
          module: "exams",
        },
      },
      {
        path: "/exams-add",
        name: "exams-add",
        component: ExamsAdd,
      },
      {
        path: "/exams-add-group/:id",
        name: "exams-add-group",
        component: ExamsAddGroup,
      },
      {
        path: "/exams-eidt-group/:id",
        name: "exams-eidt-group",
        component: ExamsEditGroup,
      },
      {
        path: "/add-question-exmas/:id",
        name: "add-question-exmas",
        component: AddQuestionExmas,
      },
      {
        path: "/exams-eidt-question/:id",
        name: "exams-eidt-question",
        component: ExamsEidtQuestion,
      },
      {
        path: "/exams-show-group/:id",
        name: "exams-show-group",
        component: ExamsShowGroup,
      },
      {
        path: "/exams-show-student/:id/:userId",
        name: "exams-show-student",
        component: ExamsShowStudent,
      },
      {
        path: "/questions-add-index/:id",
        name: "questions-add-index",
        component: QuestionsAddIndex,
      },
      {
        path: "/exams-edit/:id",
        name: "exams-edit",
        component: ExamsEdit,
      },
      {
        path: "/student-index",
        name: "student-index",
        component: StudentIndex,
        meta: {
          module: "student",
        },
      },

      {
        path: "/relation-index",
        name: "relation-index",
        component: RelationIndex,
        meta: {
          module: "relationships",
        },
      },
      {
        path: "/relation-add",
        name: "relation-add",
        component: RelationAdd,
      },
      {
        path: "/relation-edit/:id",
        name: "relation-edit",
        component: RelationEdit,
      },

      {
        path: "/add-student",
        name: "add-student",
        component: AddStudentt,
      },
      {
        path: "/edit-student/:id",
        name: "edit-student",
        component: EditStudentt,
      },
      {
        path: "/teacher-index",
        name: "teacher-index",
        component: TeacherIndex,
        meta: {
          module: "teachers",
        },
      },
      {
        path: "/add-teacher",
        name: "add-teacher",
        component: AddTeacher,
      },
      {
        path: "/edit-teacher/:id",
        name: "edit-teacher",
        component: EditTeacher,
      },
      {
        path: "/view-teacher/:id",
        name: "view-teacher",
        component: ViewTeacher,
      },
      {
        path: "/index-parent",
        name: "index-parent",
        component: IndexParent,
        meta: {
          module: "users",
        },
      },
      {
        path: "/edit-parent/:id",
        name: "edit-parent",
        component: EditParent,
      },
      {
        path: "/add-parent",
        name: "add-parent",
        component: AddParent,
      },
      {
        path: "/index-moderators",
        name: "index-moderators",
        component: IndexModerators,
      },
      {
        path: "/add-moderators",
        name: "add-moderators",
        component: AddModerators,
      },
      {
        path: "/edit-moderators/:id",
        name: "edit-moderators",
        component: EditModerators,
      },
      {
        path: "/index-slider",
        name: "index-slider",
        component: IndexSlider,
      },
      {
        path: "/add-slider",
        name: "add-slider",
        component: AddSlider,
      },

      {
        path: "/edit-slider/:id",
        name: "edit-slider",
        component: EditSlider,
      },
      {
        path: "/whowe",
        name: "whowe",
        component: WhoWeIndex,
        meta: {
          module: "subheaders",
        },
      },
      {
        path: "/add-whowe",
        name: "Addwhowe",
        component: WhoWe,
      },
      {
        path: "/edit-whowe/:id",
        name: "Editwhowe",
        component: WhoWeEdit,
      },

      {
        path: "/index-successpartners",
        name: "index-successpartners",
        component: IndexSuccesspartners,
        meta: {
          module: "partners",
        },
      },
      {
        path: "/add-successpartners",
        name: "add-successpartners",
        component: AddSuccesspartners,
      },
      {
        path: "/edit-successpartners/:id",
        name: "edit-successpartners",
        component: EditSuccesspartners,
      },

      {
        path: "/index-serves",
        name: "index-serves",
        component: IndexServes,
        meta: { module: "services" },
      },
      {
        path: "/index-features-services",
        name: "index-features-serves",
        component: IndexFeaturesServes,
        meta: {
          module: "service_features",
        },
      },
      {
        path: "/add-feature-serves",
        name: "add-feature-serves",
        component: addFeaturesServes,
      },
      {
        path: "/edit-feature-serves/:id",
        name: "edit-feature-serves",
        component: editFeaturesServes,
      },
      {
        path: "/add-serves",
        name: "add-serves",
        component: Addserves,
      },
      {
        path: "/edit-serves/:id",
        name: "edit-serves",
        component: EditServes,
      },
      {
        path: "/app-teacher",
        name: "app-teacher",
        component: AppTeacher,
      },
      {
        path: "/app-parent",
        name: "app-parent",
        component: Appparent,
      },
      {
        path: "/app-student",
        name: "app-student",
        component: AppStudent,
      },

      {
        path: "/index-blog",
        name: "index-blog",
        component: IndexBlog,
        meta: {
          module: "blogs",
        },
      },
      {
        path: "/add-blog",
        name: "add-blog",
        component: AddBlog,
      },
      {
        path: "/edit-blog/:id",
        name: "edit-blog",
        component: EditBlog,
      },
      {
        path: "/index-department",
        name: "index-department",
        component: IndexDepartment,
        meta: {
          module: "blogs_categories",
        },
      },
      {
        path: "/add-department",
        name: "add-department",
        component: AddDepartment,
      },
      {
        path: "/edit-department/:id",
        name: "edit-department",
        component: EditDepartment,
      },
      {
        path: "/opinion-client",
        name: "opinion-client",
        component: OpinionClient,
        meta: {
          module: "opinions",
        },
      },
      {
        path: "/add-opinion-client",
        name: "add-opinion-client",
        component: addOpinionClient,
      },
      {
        path: "/edit-opinion-client/:id",
        name: "edit-opinion-client",
        component: editOpinionClient,
      },
      {
        path: "/index-hashtag",
        name: "index-hashtag",
        component: IndexHashtag,
        meta: {
          module: "blogs_hashtags",
        },
      },
      {
        path: "/add-hashtag",
        name: "add-hashtag",
        component: AddHashtag,
      },
      {
        path: "/edit-hashtag/:id",
        name: "edit-hashtag",
        component: EditHashtag,
      },
      {
        path: "/index-complaints",
        name: "index-complaints",
        component: IndexComplaints,
      },
      {
        path: "/index-competion",
        name: "index-competion",
        component: IndexCompetion,
      },
      {
        path: "/add-competion",
        name: "add-competion",
        component: AddCompetion,
      },
      {
        path: "/edit-competion/:id",
        name: "edit-complaints",
        component: EditCompetion,
      },

      {
        path: "/competition-view/:id",
        name: "competition-view",
        component: CompetitionView,
      },
      {
        path: "/index-electroniclibrary",
        name: "index-electroniclibrary",
        component: Indexelectroniclibrary, // Make sure this matches the import statement
        meta: {
          module: "libraries",
        },
      },
      {
        path: "/add-electroniclibrary",
        name: "add-electroniclibrary",
        component: addElectroniclibrary, // Make sure this matches the import statement
      },

      {
        path: "/edit-electroniclibrary/:id",
        name: "edit-electroniclibrary",
        component: editElectroniclibrary, // Make sure this matches the import statement
      },
      {
        path: "/index-category",
        name: "index-category",
        component: IndexCategory,
        meta: {
          module: "libraries_categories",
        },
      },
      {
        path: "/add-category",
        name: "add-category",
        component: AddCategory,
      },
      {
        path: "/edit-category/:id",
        name: "edit-category",
        component: EditCategory,
      },
      {
        path: "/index-question-banks",
        name: "index-question-banks",
        component: QuestionBanksIndex,
      },
      {
        path: "/add-question-banks",
        name: "add-question-banks",
        component: AddQuestionBanks,
      },
      {
        path: "/edit-question-banks/:id",
        name: "edit-question-banks",
        component: EditQuestionBanks,
      },
      {
        path: "/index-header",
        name: "index-header",
        component: IndexHeader,
        meta: {
          module: "headers",
        },
      },
      {
        path: "/add-header",
        name: "add-header",
        component: AddHeader,
      },
      {
        path: "/edit-header/:id",
        name: "edit-header",
        component: EditHeader,
      },
      {
        path: "/index-counter",
        name: "index-counter",
        component: IndexCounter,
        meta: {
          module: "statistics",
        },
      },
      {
        path: "/add-counter",
        name: "add-counter",
        component: AddCounter,
      },
      {
        path: "/edit-counter/:id",
        name: "edit-counter",
        component: EditCounter,
      },

      {
        path: "/index-screen",
        name: "index-screen",
        component: IndexScreen,
        meta: {
          module: "screens",
        },
      },
      {
        path: "/add-screen",
        name: "add-screen",
        component: AddScreen,
      },
      {
        path: "/edit-screen/:id",
        name: "edit-screen",
        component: EditScreen,
      },
      {
        path: "/index-subscription",
        name: "index-subscription",
        component: IndexSubscription,
        meta: {
          module: "subscripe_group",
        },
      },
      {
        path: "/add-subscription",
        name: "add-subscription",
        component: AddSubscription,
      },
      {
        path: "/index-permission",
        name: "index-permission",
        component: IndexPermission,
        meta: {
          module: "premisson",
        },
      },
      {
        path: "/add-permission",
        name: "add-permission",
        component: AddPermission,
      },
      {
        path: "/edit-permission/:id",
        name: "edit-permission",
        component: EditPermission,
      },
      {
        path: "/exam-results",
        name: "exam-results",
        component: ExamResultIndex,
        meta: {
          module: "exam_results",
        },
      },

      {
        path: "/show-result/:id",
        name: "show-result",
        component: ExamResultShow,
      },
      {
        path: "/",
        name: "competition",
        component: Competition,
      },
      {
        path: "/live-teacher/:session_id",
        name: "live-teacher",
        component: live,
      },
      {
        path: "/Attendance-absence",
        name: "Attendanceabsence",
        component: Attendanceabsence,
      },
      {
        path: "/setting",
        name: "setting",
        component: Setting,
      },
      {
        path: "/lessons",
        name: "lessons",
        component: lessons,
        meta: {
          module: "admin_session",
        },
      },
      {
        path: "/lessonsview/:id",
        name: "lessonsview",
        component: lessonsview,
      },
      {
        path: "/exams-group/:groupId",
        name: "examsgroupindex",
        component: ExamsGroupIndex,
        meta: {
          module: "groups_exams",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/login", // Redirect to login if not authenticated
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global Authentication Guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.token) {
    // If trying to access a protected route without being authenticated, redirect to login
    next({ name: "login" });
  } else if (to.meta.guest && authStore.token) {
    // If already authenticated and trying to access a guest route, redirect to dashboard
    next({ name: "home" });
  } else {
    next(); // Proceed to the route
  }
});

export default router;
