<template>
  <section class="index_cards">
    <!-- Navbar -->
    <nav class="navbar lets">
      <!-- ... (keep navbar structure same as original) ... -->
    </nav>

    <div class="content-main-new">
      <section class="container">
        <div class="main-preparation">
          <div class="header-preparation">
            <h4>اضافة اختبار جديد</h4>
          </div>

          <!-- Form Section -->
          <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
              <div class="form-group">
                <label class="grenal" for="">المجموعة</label>
                <select v-model="selectedGroup" class="selectpicker">
                  <option value="" disabled selected hidden>
                    اختر المجموعة التى تكتب لها الواجب
                  </option>
                  <option v-for="(group, index) in groups" :key="index">
                    {{ group }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label class="grenal" for="">اسم الامتحان</label>
                <input
                  v-model="examName"
                  type="text"
                  class="form-control"
                  placeholder="ادخل اسم الامتحان"
                />
              </div>

              <!-- Questions -->
              <div
                v-for="(question, qIndex) in questions"
                :key="qIndex"
                class="quet"
              >
                <h4>السؤال {{ qIndex + 1 }}</h4>
                <hr />

                <div class="form-group">
                  <label class="grenal" for="">نوع السؤال</label>
                  <select v-model="question.type" class="selectpicker">
                    <option value="" disabled selected hidden>اختياري</option>
                    <option
                      v-for="(type, tIndex) in questionTypes"
                      :key="tIndex"
                    >
                      {{ type }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="grenal" for="">نص السؤال</label>
                  <input
                    v-model="question.text"
                    type="text"
                    class="form-control"
                    placeholder="ادخل نص السؤال"
                  />
                </div>

                <!-- Answers -->
                <div class="form-group">
                  <label class="mb-2">الاجابات*</label>
                  <div
                    v-for="(answer, aIndex) in question.answers"
                    :key="aIndex"
                    class="d-flex align-items-center append_answer"
                  >
                    <input
                      v-model="answer.text"
                      type="text"
                      class="form-control"
                      placeholder="ادخل الاجابة"
                    />
                    <button
                      @click="removeAnswer(qIndex, aIndex)"
                      class="remove btn"
                    >
                      x
                    </button>
                    <input
                      type="radio"
                      v-model="question.correctAnswer"
                      :value="aIndex"
                      class="form-check-input"
                    />
                  </div>
                  <button
                    @click="addAnswer(qIndex)"
                    class="btn btnAdd"
                    type="button"
                  >
                    + إضافة إجابة جديدة
                  </button>
                </div>

                <!-- Question Points/Time -->
                <div class="row">
                  <div class="col-lg-3 col-md-12 col-12">
                    <div class="form-group">
                      <label class="grenal" for="">وقت السؤال</label>
                      <input
                        v-model="question.time"
                        type="text"
                        class="form-control"
                        placeholder="ادخل وقت السؤال"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <div class="form-group">
                      <label class="grenal" for="">درجة السؤال</label>
                      <input
                        v-model="question.points"
                        type="text"
                        class="form-control"
                        placeholder="ادخل درجة السؤال"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div class="row">
                <div class="col-lg-8 col-md-12 col-12">
                  <div class="button-added">
                    <button
                      @click="showQuestionModal = true"
                      type="button"
                      class="btn added"
                    >
                      اضف سؤال جديد
                    </button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  <div class="button-added">
                    <button
                      @click="submitExam"
                      type="button"
                      class="btn added-add"
                    >
                      رفع الامتحان الآن
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Modals -->
    <QuestionModal
      v-if="showQuestionModal"
      @close="showQuestionModal = false"
      @add-question="addNewQuestion"
    />
  </section>
</template>

<script>
export default {
  data() {
    return {
      selectedGroup: "",
      examName: "",
      groups: ["Group 1", "Group 2", "Group 3"],
      questionTypes: ["Multiple Choice", "True/False", "Essay"],
      questions: [
        {
          type: "",
          text: "",
          answers: [{ text: "", correct: false }],
          time: "",
          points: "",
          correctAnswer: null,
        },
      ],
      showQuestionModal: false,
    };
  },
  methods: {
    addAnswer(qIndex) {
      this.questions[qIndex].answers.push({ text: "", correct: false });
    },
    removeAnswer(qIndex, aIndex) {
      this.questions[qIndex].answers.splice(aIndex, 1);
    },
    addNewQuestion(newQuestion) {
      this.questions.push(newQuestion);
      this.showQuestionModal = false;
    },
    submitExam() {
      // Handle exam submission
      console.log("Exam data:", {
        group: this.selectedGroup,
        name: this.examName,
        questions: this.questions,
      });
    },
  },
};
</script>

<style>
/* Add your CSS styles here */
</style>
