<template>
  <section class="index_cards">
    <!-- Main Content -->
    <div class="content-main-lib">
      <section class="container">
        <div class="head-reports">
          <h4>تقارير الطالب / تفاصيل الطالب</h4>
        </div>

        <div class="content-reports">
          <div class="container">
            <!-- Filters Row -->
            <div class="row g-3 mb-4">
              <div class="col-lg-8 col-md-12">
                <div class="position-relative">
                  <input
                    v-model="searchQuery"
                    class="form-control ps-5"
                    type="search"
                    placeholder="ابحث بإسم الدرس ...."
                  />
                  <i
                    class="fa-solid fa-magnifying-glass position-absolute start-0 top-50 translate-middle-y ms-3"
                  ></i>
                </div>
              </div>

              <!-- <div class="col-lg-2 col-md-6 col-12">
                  <div class="position-relative">
                    <input
                      v-model="dateRange"
                      class="form-control pe-4"
                      type="text"
                      placeholder="12 مايو - 12 يوليو"
                      @focus="showDatePicker"
                      @blur="dateRange = formatDateRange()"
                    />
                    <i
                      class="fa-solid fa-calendar-day position-absolute end-0 top-50 translate-middle-y me-3"
                    ></i>
                  </div>
                </div> -->

              <!-- <div class="col-lg-2 col-md-6 col-12">
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle w-100"
                      type="button"
                      data-bs-toggle="dropdown"
                    >
                      {{ selectedLessonType }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-for="type in lessonTypes" :key="type">
                        <a
                          class="dropdown-item"
                          @click="selectedLessonType = type"
                        >
                          {{ type }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>

            <!-- Lessons Table -->
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">الدرس</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">الحضور</th>
                    <th scope="col">من</th>
                    <th scope="col">الي</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lesson, index) in data" :key="index">
                    <td>{{ lesson.title }}</td>
                    <td>{{ lesson.date }}</td>
                    <td
                      :class="{
                        'text-success': lesson.is_attendance === 'present',
                      }"
                    >
                      <template v-if="lesson.is_attendance === 'present'">
                        <i class="fa-regular fa-circle-check"></i>
                      </template>
                      <template v-else>
                        {{ lesson.is_attendance }}
                      </template>
                    </td>
                    <td>{{ lesson.start_time }}</td>
                    <td>{{ lesson.end_time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "StudentGroupsDetails",
  data() {
    return {
      searchQuery: "",
      data: [],
      timeoutId: null, // Added timeout ID
    };
  },

  methods: {
    getData() {
      axios
        .post("teacher_student_attendance_and_departure", {
          user_id: this.$route.params.id,
          word: this.searchQuery,
        })
        .then((res) => {
          this.data = res.data.data;
          //   console.log(this.data);
        });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    searchQuery() {
      // Clear existing timeout
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      // Set new timeout with 500ms delay
      this.timeoutId = setTimeout(() => {
        this.getData();
      }, 500);
    },
  },
  beforeUnmount() {
    // Cleanup when component is destroyed
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
};
</script>
