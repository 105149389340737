<template>
  <section class="index_cards">
    <div class="content-main-lib">
      <section class="container">
        <div class="main-lib-reder">
          <div class="Educational-librar">
            <div class="librar">
              <div class="text-librar">
                <h4>تقارير الطالب</h4>
                <div class="p-text">
                  <p>
                    <img
                      class="squr"
                      src="@/assets/mida/green squr.png"
                      alt=""
                    />تقارير الحضور و الانصراف للطالب من خلال اللايف الخاص به .
                  </p>
                  <p>
                    <img
                      class="squr"
                      src="@/assets/mida/green squr.png"
                      alt=""
                    />تقارير للاختبارات اليومية علي جميع الاسئلة .
                  </p>
                  <p>
                    <img
                      class="squr"
                      src="@/assets/mida/green squr.png"
                      alt=""
                    />تقارير للواجبات المطلوبة منه من خلال المعلمين المنضم لهم .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-reder">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12">
                <RouterLink
                  class="img-reder"
                  :to="`/student-groups-details-report/${$route.params.id}`"
                >
                  <img src="@/assets/mida/go-end.png" alt="" />
                </RouterLink>
              </div>
              <!-- <div class="col-lg-3 col-md-6 col-12">
                <RouterLink
                  class="img-reder"
                  :to="`/student-groups-details-achievements/${$route.params.id}`"
                >
                  <img src="@/assets/mida/engazat.png" alt="" />
                </RouterLink>
              </div> -->
              <div class="col-lg-3 col-md-6 col-12">
                <RouterLink
                  class="img-reder"
                  :to="`/student-groups-details-exams/${$route.params.id}`"
                >
                  <img src="@/assets/mida/tests.png" alt="" />
                </RouterLink>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <RouterLink
                  class="img-reder"
                  :to="`/student-groups-details-student-evaluations/${$route.params.id}`"
                >
                  <img src="@/assets/mida/rate.png" alt="" />
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
