<template>
  <div class="col-lg-6 col-md-12 col-12 h-full">
    <!-- <h4>معدل الاحصائيات على الموقع</h4> -->
    <div class="all-info h-full">
      <div class="infos w-100">
        <div class="info">
          <img src="@/assets/photos/users.png" alt="" />
          <div class="contentt">
            <h2>اجمالى الدروس</h2>
            <h3>{{ totalLesson }}</h3>
          </div>
        </div>
        <div class="info">
          <img src="@/assets/photos/users.png" alt="" />
          <div class="contentt">
            <h2>اجمالى الطلاب</h2>
            <h3>{{ totalStudent }}</h3>
          </div>
        </div>
      </div>
      <div class="infos w-100">
        <div class="info">
          <img src="@/assets/photos/users.png" alt="" />
          <div class="contentt">
            <h2>المجموعات</h2>
            <h3>{{ groups }}</h3>
          </div>
        </div>
        <div class="info">
          <img src="@/assets/photos/users.png" alt="" />
          <div class="contentt">
            <h2>التقييمات</h2>
            <h3>{{ rates }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      totalLesson: null,
      groups: null,
      rates: null,
      totalStudent: null,
    };
  },
  methods: {
    getStatistics() {
      axios.post("/teacher_site_statistics_rate").then((res) => {
        // console.log(res.data.data);

        this.totalLesson = res.data.data.session_count;
        this.groups = res.data.data.group_count;
        this.rates = res.data.data.rate_count;
        this.totalStudent = res.data.data.user_count;
      });
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: "regular";
}
.activity {
  background-color: white;
  border-radius: 10px;
  width: 32.3%;
  margin-bottom: 178px !important;
}
.dd {
  width: 90%;
  margin-right: 20px;
}
.student {
  background: white;
  border-radius: 14px;
  padding: 11px;
  margin-right: 21px;
  width: 60%;
}
.act {
  position: relative;
  border: 1px solid #00000029;
  /* width: fit-content; */
  padding: 0.6rem 3rem;
  margin-top: 16px;
  border-radius: 6px;
  margin-bottom: 10px;
  h4 {
    font-size: 15px;
    margin-right: 24px;
  }
  h5 {
    font-size: 15px;
    margin-right: 24px;
    color: #8d8d8d;
  }

  img {
    position: absolute;
    top: -21px;
    right: -8px;
    height: 74px;
  }
}
.lesson {
  padding: 9px;

  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    color: var(--main);
  }
}
.images {
  background: white;
  border-radius: 10px;

  img {
    margin-top: 59px;
    margin-right: 164px;
    margin-bottom: 64px;
  }
}
h4 {
  font-size: 19px;
  font-family: "regular";
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: 0;
  margin-left: auto;
}
.all-info {
  display: flex;
  gap: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  //   flex-wrap: wrap;
}
.all {
  background: #f0eeee;
}

.info {
  display: flex;
  align-items: center;
  background-color: #eef2f2;
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 1rem 0;
  //   padding-left: 85px;
  margin-bottom: 33px;
  margin-top: 12px;
  width: 100%;

  img {
    background-color: #b2d23f;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
  h2 {
    font-family: "regular";
    font-size: 17px;
  }
  h3 {
    font-size: 14px;
    font-family: "regular";
    font-weight: bold;
  }
}

@media screen and (max-width: 425px) {
  .images {
    margin-bottom: 15px;
    img {
      margin-right: 110px;
    }
  }
  .all-info {
    display: block;
  }
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 89.8%;
  }
  .activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 90.3%;
    margin-right: 17px;
  }
}

@media screen and (max-width: 768px) {
  .images img {
    margin-top: 59px;
    margin-right: 118px;
    margin-bottom: 64px;
  }
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 93.3%;
  }
  .activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 94.3%;
    margin-right: 18px;
    margin-bottom: 0 !important;
  }
}

// @media screen and (max-width: 1440px) {
//   .imgg {
//     flex: 0 0 auto;
//     width: 97.3%;
//     margin-bottom: 16px;
//   }
//   .col-lg-4 {
//     flex: 0 0 auto;
//     width: 97.5%;
//   }

//   .student {
//     background: white;
//     border-radius: 14px;
//     padding: 11px;
//     margin-right: 21px;
//     width: 94%;
//   }
//   .activity {
//     flex: 0 0 auto;
//     width: 94.5%;
//     margin-right: 17px;
//   }
//   .info {
//     display: flex;
//     align-items: center;
//     background-color: #eef2f2;
//     border-radius: 10px;
//     padding: 0.5rem;
//     margin: 1rem 0;
//     padding-left: 365px;
//     margin-bottom: 33px;
//     margin-top: 12px;
//   }
// }
</style>
