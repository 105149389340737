<template>
  <div class="col-lg-7 col-md-12 col-12">
    <div class="all-chart-1">
      <h4>معدل توزيع الطالب</h4>
      <Chart
        type="line"
        :data="chartDataLine"
        :options="chartOptionsLine"
        class="h-[30rem]"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      chartDataLine: null,
      chartOptionsLine: null,
      months: [],
      grade: [],
    };
  },

  methods: {
    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: this.months,
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: documentStyle.getPropertyValue("--p-cyan-500"),
            borderColor: documentStyle.getPropertyValue("--p-cyan-500"),
            data: this.grade,
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    },

    getData() {
      axios
        .post("teacher_group_student_progress_rate", {
          group_id: this.$route.params.id,
        })
        .then((res) => {
          this.months = res.data.data.months;
          this.grade = res.data.data.grade;

          this.chartDataLine = this.setChartData();
          this.chartOptionsLine = this.setChartOptions();
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
