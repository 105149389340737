<template>
  <div class="container">
    <div class="row">
      <RouterLink
        :to="`/student-groups-details/${student.id}`"
        class="col-lg-4 col-md-12 col-12 mt-2"
        v-for="student in students"
        :key="student.id"
      >
        <div class="card w-100" style="width: 18rem">
          <img :src="student.image" class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title">{{ student.name }}</h5>
          </div>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      students: [],
    };
  },
  methods: {
    getStudents() {
      axios
        .post("teacher_group_students", { group_id: this.$route.params.id })
        .then((res) => {
          this.students = res.data.data;
        });
    },
  },
  mounted() {
    this.getStudents();
  },
};
</script>

<style></style>
