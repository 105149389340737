import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useAppTeacherStore = defineStore("appteacher", {
  state: () => ({
    Appteacher: [],
  }),

  actions: {
    async AddAppteacher(appteatcherData) {
      console.log("📥 بيانات المعلم المستلمة:", appteatcherData);

      const formData = new FormData();

      // إضافة البيانات إلى formData
      Object.keys(appteatcherData).forEach((key) => {
        if (appteatcherData[key] && key !== "certificateFile") {
          if (key === "image") {
            if (appteatcherData[key] instanceof File) {
              formData.append("image", appteatcherData[key]);
            }
          } else {
            formData.append(key, appteatcherData[key]);
          }

          console.log(`📌 تم إضافة: ${key} -> ${appteatcherData[key]}`);
        }
      });

      try {
        const response = await axios.post("store_application_info", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status) {
          this.Appteacher = response.data.data;
          Swal.fire({
            icon: "success",
            title: "نجاح",
            text: "تمت إضافة المعلم بنجاح!",
          });
          router.push("/"); // إعادة توجيه بعد النجاح
          console.log("✅ بيانات المعلم المحدثة:", this.Appteacher);
        } else {
          Swal.fire({ icon: "error", title: "خطأ", text: response.data.message });
        }
      } catch (error) {
        console.error("❌ خطأ أثناء الإرسال:", error);

        let errorMessage = "حدث خطأ أثناء الإرسال. حاول مرة أخرى.";

        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: errorMessage
        });
      }


    },

    async fetchDatateacher() {
      try {
        const response = await axios.post("fetch_application_info", { type: 1 });

        if (response.data.status) {
          this.Appteacher = response.data.data;
          console.log("📊 بيانات المعلمين المحدثة:", this.Appteacher);
        } else {
          console.error("❌ خطأ أثناء جلب بيانات المعلمين.");
          Swal.fire({ icon: "error", title: "خطأ", text: "فشل في جلب بيانات المعلمين." });
        }
      } catch (error) {
        console.error("❌ خطأ أثناء الجلب:", error);
        Swal.fire({ icon: "error", title: "خطأ", text: "حدث خطأ أثناء جلب البيانات." });
      }
    },
  },
});
