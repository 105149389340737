<template>
  <div class="app-parent">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <header-pages
        :showButton="false"
        link="/groups"
        title=" إضافة ولي امر جديد "
        class="mb-4"
      />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div
              v-if="!parent.imageSrc"
              class="upload-icon"
              style="margin-top: 0"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="parent.imageSrc" class="avatar-preview">
              <img :src="parent.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
          <!-- <span class="error-feedback" v-if="v$.parent.imageSrc.$error">
            {{ getErrorMessage(v$.parent.imageSrc) }}
          </span> -->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اسم ولي امر </label>
          <div class="input">
            <input
              v-model="parent.name"
              placeholder=" ادحل اسم ولي امر"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.parent.name.$error">
            {{ getErrorMessage(v$.parent.name) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكتروني </label>
          <div class="input">
            <input
              v-model="parent.email"
              placeholder="ادخل البريد الالكتروني"
              type="email"
            />
          </div>
          <span class="error-feedback" v-if="v$.parent.email.$error">
            {{ getErrorMessage(v$.parent.email) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> تاريخ الميلاد </label>
            <input v-model="parent.date_of_birth" type="date" />
          </div>
          <span class="error-feedback" v-if="v$.parent.date_of_birth.$error">
            {{ getErrorMessage(v$.parent.date_of_birth) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input" id="pass">
            <input
              v-model="parent.password"
              placeholder="كلمة المرور"
              :type="showPassword ? 'text' : 'password'"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i v-if="showPassword" class="fa fa-eye"></i>
              <i v-else class="fa fa-eye-slash"></i>
            </span>
          </div>
          <span class="error-feedback" v-if="v$.parent.password.$error">
            {{ getErrorMessage(v$.parent.password) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور</label>
          <div class="input" id="pass">
            <input
              v-model="parent.password_confirmation"
              placeholder="تأكيد كلمة المرور"
              :type="showPassword ? 'text' : 'password'"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i v-if="showPassword" class="fa fa-eye"></i>
              <i v-else class="fa fa-eye-slash"></i>
            </span>
          </div>
          <span
            class="error-feedback"
            v-if="v$.parent.password_confirmation.$error"
          >
            {{ getErrorMessage(v$.parent.password_confirmation) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> العمر </label>
            <input v-model="parent.age" type="number" />
          </div>
          <span class="error-feedback" v-if="v$.parent.age.$error">
            {{ getErrorMessage(v$.parent.age) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تفاصيل العنوان </label>
          <div class="input">
            <input
              v-model="parent.address"
              placeholder=" اكتب تفاصيل العنوان"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.parent.address.$error">
            {{ getErrorMessage(v$.parent.address) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الهاتف </label>
          <div class="input">
            <input
              @input="validateInput"
              v-model="parent.phone"
              placeholder="رقم الهاتف"
              type="tel"
            />
          </div>
          <span class="error-feedback" v-if="v$.parent.phone.$error">
            {{ getErrorMessage(v$.parent.phone) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">فصيلة الدم</label>
          <multiselect
            v-model="parent.blood_type_id"
            :clear-on-select="true"
            :hide-selected="true"
            deselect-label=""
            :options="bloodOptions"
            label="title"
            placeholder="فصيلة الدم"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.parent.blood_type_id.$error">
            {{ getErrorMessage(v$.parent.blood_type_id) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختر المجموعه</label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="اختر المجموعه"
            v-model="parent.group_ids"
            :multiple="true"
            :options="groupOptions"
            label="title"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.parent.group_ids.$error">
            {{ getErrorMessage(v$.parent.group_ids) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الدوله</label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="اختار الدوله"
            v-model="parent.country_id"
            :options="countryptions"
            label="title"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.parent.country_id.$error">
            {{ getErrorMessage(v$.parent.country_id) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="identitynumber">
            <label for="identity_number"> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                id="identity_number"
                v-model="parent.identity_number"
                placeholder="ادخل رقم الهويه"
                type="text"
              />
            </div>
            <span
              class="error-feedback"
              v-if="v$.parent.identity_number.$error"
            >
              {{ getErrorMessage(v$.parent.identity_number) }}
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <label for="identity_type">نوع الهوية</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                id="flexRadioDefault5"
                v-model="parent.identity_type"
                class="form-check-input"
                name="identity_type"
                type="radio"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                id="flexRadioDefault6"
                v-model="parent.identity_type"
                class="form-check-input"
                name="identity_type"
                type="radio"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <label for="gender">نوع</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                id="flexRadioDefault11"
                v-model="parent.gender"
                class="form-check-input"
                name="gender"
                type="radio"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault11">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                id="flexRadioDefault10"
                v-model="parent.gender"
                class="form-check-input"
                name="gender"
                type="radio"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault10">
                انثي
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useParentsAddStore } from "@/stores/parent/parentStoreAdd";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "pinia";
import Swal from "sweetalert2";

export default {
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      showPassword: false,
      store: useParentsAddStore(),
      v$: useVuelidate(),
      parent: {
        name: "",
        email: "",
        phone: "",
        password: "",
        identity_type: 0,
        identity_number: "",
        date_of_birth: "",
        password_confirmation: "",
        age: "",
        gender: 0,
        address: "",
        image: null,
        imageSrc: "",
        blood_type_id: null,
        group_ids: [],
        country_id: null,
        type: 1,
      },
      country_valus: null,
      group_valus: null,
      bloods_valus: null,
      groupOptions: [],
      countryptions: [],
      bloodOptions: [],
    };
  },
  computed: {
    calculatedAge() {
      if (!this.parent.date_of_birth) return 0;
      return this.calculateAge(this.parent.date_of_birth);
    },
    ...mapState(useParentsAddStore, {
      teachers: (state) => state.teachers,
    }),
  },
  validations() {
    return {
      parent: {
        name: { required },
        email: { required },
        phone: { required },
        password: { required },
        identity_type: { required },
        identity_number: { required },
        date_of_birth: { required },
        password_confirmation: { required },
        blood_type_id: { required },
        group_ids: { required },
        country_id: { required },
        address: { required },
        // imageSrc: { required },
        age: { required },
      },
    };
  },
  methods: {
    calculateAge(birthDate) {
      const today = new Date();
      const dob = new Date(birthDate);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < dob.getDate())
      ) {
        age--;
      }
      return age;
    },
    validateInput() {
      this.parent.phone = this.parent.phone.replace(/[^0-9]/g, "");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    updatecountry() {
      this.parent.country_id =
        this.country_valus && this.country_valus.id
          ? this.country_valus.id
          : null;
      console.log(this.parent.country_id);
    },
    updateblood() {
      this.parent.blood_type_id =
        this.bloods_valus && this.bloods_valus.id ? this.bloods_valus.id : null;
      console.log(this.parent.blood_type_id);
    },

    removeImage() {
      this.parent.image = null;
      this.parent.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.parent.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.parent.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    // async submitForm() {
    //   this.v$.$validate();
    //   if (this.v$.$error) {
    //     return;
    //   }
    //   try {
    //     const ParentStore = useParentsAddStore();
    //     if (!ParentStore) {
    //       throw new Error("Failed to load Courses store");
    //     }
    //     if (
    //       !this.parent.name ||
    //       !this.parent.email ||
    //       !this.parent.password ||
    //       !this.parent.password_confirmation ||
    //       !this.parent.phone ||
    //       !this.parent.identity_type ||
    //       !this.parent.identity_number ||
    //       !this.parent.date_of_birth ||
    //       !this.parent.blood_type_id ||
    //       !this.parent.group_ids ||
    //       !this.parent.country_id
    //     ) {
    //       Swal.fire("Error", "Please fill in all fields", "error");
    //       return;
    //     }
    //     await this.ParentStore.addParents (this.parent);
    //   } catch (error) {
    //     console.error("Error in submitForm:", error);
    //   }
    // },

    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }

      if (this.parent) {
        if (this.parent.blood_type_id) {
          this.parent.blood_type_id = this.parent.blood_type_id.id;
        }

        if (Array.isArray(this.parent.group_ids)) {
          this.parent.group_ids = this.parent.group_ids.map((item) => item.id);
        } else {
          this.parent.group_ids = [];
        }

        if (this.parent.country_id) {
          this.parent.country_id = this.parent.country_id.id;
        }

        await this.store.addParents(this.parent);
        // this.$router.go(-1);
      } else {
        console.error("Parent data is not defined");
      }
    },

    async fetchcountries() {
      await this.store.fetchcountries();
      this.countryptions = this.store.countries;
    },
    async fetchbolood() {
      await this.store.fetchblood();
      this.bloodOptions = this.store.bloods;
    },
    async fetchgroup() {
      await this.store.fetchgroup();
      this.groupOptions = this.store.groups;
    },
  },
  mounted() {
    this.fetchcountries();
    this.fetchbolood();
    this.fetchgroup();
  },
  watch: {
    "parent.date_of_birth"(newVal) {
      if (newVal) {
        this.parent.age = this.calculateAge(newVal);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-feedback {
  color: red;
}
#pass {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.11);
  background-color: #e8f0fe;
  border-radius: 15px;
  margin-top: 0.3rem;
  input {
    border: 0 !important;
    padding: 0.75rem !important;
    margin-top: 0;
  }
}
</style>
