<template>
  <div
    class="modal fade"
    :id="'View-details-' + examId"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog View-details">
      <div class="modal-content">
        <!-- {{ examId }} -->
        <div class="modal-header"></div>
        <div class="modal-body View-details">
          <div class="viwe">
            <div class="main-Integrated-testing">
              <div class="Integrated testing">
                <img src="mida/accordind.png" alt="" />
              </div>
              <div class="Integrated-testing-text">
                <h4>
                  {{ details.name }}
                </h4>
                <h3>{{ details.group_name }}</h3>
              </div>
            </div>
            <!-- <div class="button-viwe-details">
              <button
                type="button"
                class="btn add-rat"
                data-bs-toggle="modal"
                data-bs-target="#add-rate"
              >
                اضافة تقييم عام
              </button>
            </div> -->
          </div>
          <div class="text-details">
            <h4>
              <i class="fa-solid fa-list-check"></i>اجمالى الأسألة:<span
                >{{ details.questions?.length }} سؤال</span
              >
            </h4>
            <!-- <h4>
              <i class="fa-regular fa-square-check"></i>الاجابات الصحيحة:<span
                >10</span
              >
            </h4> -->
            <!-- <h4>
              <i class="fa-solid fa-square-xmark"></i>الاجابات الخاطئة:<span
                >10</span
              >
            </h4> -->
            <h4>
              <i class="fa-regular fa-calendar-days"></i>تاريخ الواجب:<span
                >{{ details.start_date }} - {{ details.start_time }}</span
              >
            </h4>
            <!-- <h4>
              <i class="fa-regular fa-calendar-days"></i>الدرجة النهائية:<span
                >80 / 40</span
              >
            </h4> -->
          </div>
          <hr />
          <div class="worng">
            <h4>الاجابات الخاطئة</h4>
          </div>

          <div
            class="lest-details"
            v-for="item in details.questions"
            :key="item.id"
          >
            <h3>{{ item.question }}</h3>
            <!-- <h6>فلا تقربوها</h6> -->
            <div class="d-flex">
              <div class="d-flex text-success">
                <span class="mx-2">الاجابة الصحيحة</span> :
                <ul class="list-unstyled d-flex gap-2 ms-2 ">
                  <li v-for="answer in item.answers" :key="answer.id">
                    {{ answer.answer }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="line-af"></div> -->
        </div>
        <!-- <div class="modal-footer"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      details: {},
    };
  },
  methods: {
    getDetails() {
      axios
        .post("teacher_group_exam_details", { exam_id: this.examId })
        .then((res) => {
          this.details = res.data.data;
        });
    },
    handleModalShow() {
      this.getDetails();
    },
  },
  mounted() {
    const modalId = 'View-details-' + this.examId;
    this.modalElement = document.getElementById(modalId);
    
    if (this.modalElement) {
      this.modalElement.addEventListener('show.bs.modal', this.handleModalShow);
    }
  },
  beforeDestroy() {
    if (this.modalElement) {
      this.modalElement.removeEventListener('show.bs.modal', this.handleModalShow);
    }
  },
  props: {
    examId: {
      type: Number,
    },
  },
};
</script>