<template>
  <div v-if="showsidebar" class="main-side">
    <div
      :class="[
        'sidebar',
        localIsCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded',
      ]"
    >
      <ul class="sidebar-menu">
        <div class="navbar-toggle" @click="toggleSidebar">
          <i :class="isCollapsed ? 'fas fa-bars' : 'fas fa-times'"></i>
        </div>
        <li
          v-for="(item, index) in permittedMenuItems"
          :key="index"
          class="sidebar-item"
        >
          <!-- Menu Item Link -->
          <router-link
            v-if="!item.submenu"
            :to="item.route"
            class="sidebar-link"
            @click="closeSidebarOnItemClick"
          >
            <i :class="item.icon"></i>
            <span v-if="!localIsCollapsed">{{ item.name }}</span>
          </router-link>

          <!-- Submenu Item -->
          <div v-else>
            <div class="sidebar-link" @click="toggleSubmenu(item)">
              <i :class="item.icon"></i>
              <span v-if="!localIsCollapsed">{{ item.name }}</span>
              <i
                v-if="!localIsCollapsed"
                :class="
                  item.expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
                "
                class="up-down-icon"
                style="margin-inline-start: auto"
              ></i>
            </div>
            <ul v-if="item.expanded && !localIsCollapsed" class="submenu">
              <li
                v-for="(subItem, subIndex) in item.submenu"
                :key="subIndex"
                class="sidebar-subitem"
              >
                <router-link
                  :to="subItem.route"
                  class="sidebar-link"
                  @click="closeSidebarOnItemClick"
                >
                  <i :class="subItem.icon"></i>
                  <span>{{ subItem.name }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth/AuthStore";
import { mapState } from "pinia";

export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localIsCollapsed: this.isCollapsed,
      toggleIcon: "fas fa-bars",
      menuItems: this.getMenuItems(),
      roles: JSON.parse(localStorage.getItem("user"))?.roles,
    };
  },
  computed: {
    ...mapState(useAuthStore, {
      data: (state) => state.data.data,
    }),
    permittedMenuItems() {
      // Retrieve user and permitted modules
      const user = JSON.parse(localStorage.getItem("user"));
      const userModules =
        user?.roles?.length > 0
          ? user?.roles[0]?.modules.map((mod) => mod.name)
          : [];

      // Map over the menu items to create a new array without mutating the original state
      return this.menuItems?.length > 0
        ? this.menuItems
            .map((item) => {
              // If item has a submenu, filter it and return a new object
              if (item.submenu) {
                const permittedSubmenu = item.submenu.filter((sub) => {
                  return !sub.module || userModules.includes(sub.module);
                });
                return { ...item, submenu: permittedSubmenu };
              }
              // For items with a module, check if user has permission
              return item;
            })
            .filter((item) => {
              // Check if the item itself has a module, or if it has a non-empty submenu
              if (item.module) {
                return userModules.includes(item.module);
              }
              if (item.submenu) {
                return item.submenu.length > 0;
              }
              return true;
            })
        : [];
    },
    showsidebar() {
      return this.$route.path !== "/live-teacher";
    },
  },
  watch: {
    isCollapsed(newValue) {
      this.localIsCollapsed = newValue;
      this.updateToggleIcon();
    },
  },
  methods: {
    toggleSidebar() {
      this.localIsCollapsed = !this.localIsCollapsed;
      this.$emit("update:isCollapsed", this.localIsCollapsed);
      this.updateToggleIcon();
    },
    closeSidebarOnItemClick() {
      if (window.innerWidth <= 1024) {
        this.localIsCollapsed = true;
        this.$emit("update:isCollapsed", this.localIsCollapsed);
        this.updateToggleIcon();
      }
    },
    toggleSubmenu(item) {
      // Locate the corresponding item in the original menuItems array
      const target =
        this.menuItems?.length > 0
          ? this.menuItems.find((menuItem) => menuItem.name === item.name)
          : null;
      if (target && target.submenu) {
        target.expanded = !target.expanded;
      }
    },
    openSidebarOnHover() {
      if (this.localIsCollapsed && window.innerWidth > 1024) {
        this.localIsCollapsed = false;
        this.updateToggleIcon();
      }
    },
    closeSidebarOnLeave() {
      if (!this.localIsCollapsed && window.innerWidth > 1024) {
        this.localIsCollapsed = true;
        this.updateToggleIcon();
      }
    },
    handleResize() {
      if (window.innerWidth <= 600) {
        this.localIsCollapsed = true;
      } else if (window.innerWidth > 600 && window.innerWidth <= 1024) {
        this.localIsCollapsed = true;
      } else if (window.innerWidth > 1024 && !this.localIsCollapsed) {
        this.localIsCollapsed = false;
      }
      this.$emit("update:isCollapsed", this.localIsCollapsed);
      this.updateToggleIcon();
    },
    updateToggleIcon() {
      this.toggleIcon = this.localIsCollapsed ? "fas fa-bars" : "fas fa-times";
    },
    getMenuItems() {
      const user = localStorage.getItem("user");
      const parsedUser = user ? JSON.parse(user) : null;
      const type = parsedUser ? parsedUser.is_employed : null;

      if (type === 0) {
        console.log("employed");
        return [
          {
            name: "الرئيسيه",
            route: "/",
            icon: "fas fa-home",
            module: "dashboard",
          },
          {
            name: "الموظفين",
            route: "/employees",
            icon: "fa-solid fa-building-user",
            module: "employee",
          },

          {
            name: "التعليم",
            icon: "fa-brands fa-leanpub",
            submenu: [
              {
                name: "الدورات التعليمية",
                route: "/courses",
                icon: "fa-regular fa-clipboard",
                module: "courses",
              },
              {
                name: "النتائج",
                route: "/exam-results",
                icon: "fa-regular fa-clipboard",
                module: "exam_results",
              },
              // {
              //   name: "المجموعات",
              //   name: "المجموعات",
              //   route: "/groups",
              //   icon: "fa-regular fa-clipboard",
              // },

              {
                name: "الأمتحانات",
                route: "/exams",
                icon: "fa-regular fa-clipboard",
                module: "exams",
              },
              // {
              //   name: "بنك الاسئلة",
              //   route: "/index-question-banks",
              //   icon: "fa-regular fa-clipboard",
              // },
              {
                name: "الحصص",
                route: "/lessons",
                icon: "fa-regular fa-clipboard",
                module: "admin_session",
              },
            ],
          },
          {
            name: "المستخدمين",
            icon: "fa-solid fa-users-viewfinder",
            submenu: [
              {
                name: "الطلاب",
                route: "/student-index",
                icon: "fa-solid fa-user",
                module: "student",
              },
              {
                name: "المعلمين ",
                route: "/teacher-index",
                icon: "fa-solid fa-person-chalkboard",
                module: "teachers",
              },
              {
                name: "أولياء الامور",
                route: "/index-parent",
                icon: "fa-solid fa-people-roof",
                module: "users",
              },
              {
                name: "صله القرابه",
                route: "/relation-index",
                icon: "fa-solid fa-people-roof",
                module: "relationships",
              },
              {
                name: "المشرفين",
                route: "/index-moderators",
                icon: "fa-solid fa-users-gear",
                module: "employees",
              },
              {
                name: "الوظائف",
                route: "/index-jops",
                icon: "fa-solid fa-people-roof",
                module: "job_type",
              },
            ],
          },
          {
            name: "الموقع الأكتروني",
            icon: "fa-solid fa-earth-americas",
            submenu: [
              {
                name: "سيكشن الهيدر ",
                route: "/index-header",
                icon: "fa-solid fa-section",
                module: "headers",
              },
              {
                name: "سيكشن الكاونتر ",
                route: "/index-counter",
                icon: "fa-solid fa-calculator",
                module: "statistics",
              },
              {
                name: "الاسكرين",
                route: "/index-screen",
                icon: "fa-solid fa-solar-panel",
                module: "screens",
              },

              {
                name: "أراء العملاء ",
                route: "/opinion-client",
                icon: "fa-solid fa-sheet-plastic",
                module: "opinions",
              },

              {
                name: "من نحن ",
                route: "/whowe",
                icon: "fa-solid fa-hand",
                module: "subheaders",
              },
              {
                name: "الخدمات",
                route: "/index-serves",
                icon: "fa-solid fa-server",
                module: "services",
              },
              {
                name: "شركاء النجاح",
                route: "/index-successpartners",
                icon: "fa-solid fa-diagram-next",
                module: "partners",
              },
              {
                name: "الخدمات المميزه",
                route: "/index-features-services",
                icon: "fa-solid fa-server",
                module: "service_features",
              },
              {
                name: "تطبيق المعلم",
                route: "/app-teacher",
                icon: "fa-solid fa-mobile-retro",
                module: "app_info_teacher",
              },
              {
                name: "تطبيق الطالب",
                route: "/app-student",
                icon: "fa-solid fa-mobile-retro",
                module: "app_info_student",
              },
              {
                name: "تطبيق ولي الأمر",
                route: "/app-parent",
                icon: "fa-solid fa-mobile-retro",
                module: "application_info",
              },
            ],
          },
          {
            name: "المدونه",
            icon: "fa-solid fa-blog",

            submenu: [
              {
                name: " عرض المدونه",
                route: "/index-blog",
                icon: "fa-solid fa-user",
                module: "blogs",
              },
              {
                name: " الأقسام ",
                route: "/index-department",
                icon: "fa-solid fa-person-chalkboard",
                module: "blogs_categories",
              },
              {
                name: " الهشتاج",
                route: "/index-hashtag",
                icon: "fa-solid fa-people-roof",
                module: "blogs_hashtags",
              },
            ],
          },
          // {
          //   name: "التقارير",
          //   icon: "fa-solid fa-clipboard",
          //   submenu: [
          //     {
          //       name: "  التقارير العامه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: " تقارير الأنجازات ",
          //       route: "/#",
          //       icon: "fa-solid fa-person-chalkboard",
          //     },
          //     {
          //       name: " تقارير الحضور والأنصراف",
          //       route: "/#",
          //       icon: "fa-solid fa-people-roof",
          //     },
          //     {
          //       name: "تقارير الأمتحانات",
          //       route: "/#",
          //       icon: "fa-solid fa-users-gear",
          //     },
          //     {
          //       name: "   تقارير المسابقات ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "    تقارير السلوكيه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "    تقارير السلوكيه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "    تقارير الاكادميه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "     التقارير الفائته ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "     التقارير التفصيليه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //     {
          //       name: "     التقارير الجمعيه أو المدرسه ",
          //       route: "/#",
          //       icon: "fa-solid fa-user",
          //     },
          //   ],
          // },
          // {
          //   name: "الأرشيف",
          //   route: "/#",
          //   icon: "fa-regular fa-newspaper",
          // },

          {
            name: "المكتبه الألكترونيه",
            route: "/index-electroniclibrary",
            icon: "fa-solid fa-book-open-reader",
            module: "libraries",
          },
          {
            name: " الاقسام",
            route: "/index-category",
            icon: "fa-solid fa-list",
            module: "libraries_categories",
          },
          // {
          //   name: "الشكاوي",
          //   route: "/index-complaints",
          //   icon: "fa-solid fa-clipboard-question",
          // },
          {
            name: "الأشتراكات",
            route: "/index-subscription",
            icon: "fa-solid fa-thumbtack",
            module: "subscripe_group",
          },
          {
            name: "الصالحيات",
            route: "/index-permission",
            icon: "fa-solid fa-shield",
            module: "premisson",
            // icon: "fa-regular fa-newspaper",
          },
          {
            name: "الاعدادات العامة",
            route: "/setting",
            icon: "fa-solid fa-gear",
            module: "general_setting",
          },
          {
            name: "الشكاوي ",
            route: "/index-complaints",
            icon: "fa-solid fa-handshake",
            module: "contact",
          },
        ];
      } else if (type === 1) {
        // console.log("teacher");
        return [
          { name: "الرئيسيه", route: "/home-page", icon: "fas fa-home" },
          {
            name: "المقرأه الالكترونيه",
            route: "/electronic-reader",
            icon: "fa-solid fa-book-open-reader",
          },
          {
            name: "المجموعات",
            route: "/teacher-groups",
            icon: "fa-solid fa-users-viewfinder",
          },
          {
            name: "الاختبارات",
            route: "/teacher-exams",
            icon: "fa-solid fa-file",
          },
          {
            name: "اعدادات الحساب",
            route: "/teacher-setting",
            icon: "fa-solid fa-gear",
          },
          // {
          //   name: "الواجبات",
          //   route: "/teacher-assignments",
          //   icon: "fa-solid fa-file",
          // },
          // {
          //   name: "التعليم",
          //   icon: "fa-brands fa-leanpub",
          //   submenu: [
          //     {
          //       name: "المجموعات",
          //       route: "/teacher-group",
          //       icon: "fa-regular fa-clipboard",
          //     },
          //   ],
          // },
        ];
      } else {
        console.log("إخفاء الـ sidebar أو عرض رسالة.");
      }
    },
  },

  created() {
    this.localIsCollapsed = this.isCollapsed;
    this.updateToggleIcon();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped lang="scss">
.navbar-toggle {
  text-align: end;
  margin-top: 1rem;
  margin-left: 1rem;
  i {
    border-radius: 5px;
    border: 1px solid var(--main);
    padding: 0.5rem;
  }
}
.sidebar {
  transition: all 0.7s ease !important;
}
</style>
