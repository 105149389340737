<template>
  <section class="index_cards">
    <div class="content-main-home">
      <div class="homework-ALL">
        <section class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="head-group">
                <h4>الاختبارات</h4>
              </div>
            </div>
            <div class="col-lg-2 col-md-12 col-12"></div>
            <div class="col-lg-2 col-md-12 col-12">
              <RouterLink :to="`/exams-add-group/${$route.params.id}`">
                <button type="button" class="btn homework">
                  اضافة اختبار جديد
                </button>
              </RouterLink>
            </div>
          </div>
          <div class="main-quran">
            <div class="row">
              <div class="col-lg-10 col-md-9 col-12">
                <form class="form-group">
                  <!-- <i class="fa-solid fa-magnifying-glass teacher"></i> -->
                  <input
                    class="form-control teacher"
                    type="search"
                    placeholder="ابحث بإسم الدرس ...."
                    aria-label="Search"
                    v-model="search"
                  />
                </form>
              </div>

              <!-- <div class="col-lg-2 col-md-3 col-12">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle teacher"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    مجموعة العهد الحديث
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">0</a>
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>
            <div class="video-quran">
              <div class="row">
                <ExamCard v-for="exam in exams" :key="exam" :item="exam" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import ExamCard from "@/components/exams/card.vue";
import axios from "axios";
import { debounce } from "lodash";

export default {
  data() {
    return {
      exams: [],
      search: "",
    };
  },
  methods: {
    getExams: debounce(function () {
      axios
        .post("teacher_group_exams", {
          group_id: this.$route.params.id,
          word: this.search,
        })
        .then((res) => {
          this.exams = res.data.data;
        });
    }, 500),
  },
  watch: {
    search() {
      this.getExams();
    },
  },
  mounted() {
    this.getExams();
  },
  components: {
    ExamCard,
  },
};
</script>
