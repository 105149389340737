<template>
  <div class="exams">
    <div class="head">
      <h4>الامتحانات</h4>
      <router-link
        v-if="create"
        :to="'/exams-add-group/' + $route.params.groupId"
      >
        <button class="add">اضافة امتحان</button>
      </router-link>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">اسم الامتحان</th>
            <th scope="col">تاريخ البدايه</th>
            <th scope="col">تاريخ النهايه</th>
            <th scope="col">وقت البدايه</th>
            <th scope="col">وقت النهايه</th>
            <th scope="col">المده</th>
            <th scope="col">حاله الامتحان</th>
            <th scope="col">action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="exams.length === 0">
            <td colspan="9" class="text-center">لا توجد بيانات لعرضها</td>
          </tr>
          <tr v-for="(exam, index) in exams" :key="index">
            <td>{{ exam.id }}</td>
            <td>{{ exam.name }}</td>
            <td>{{ exam.start_date }}</td>
            <td>{{ exam.end_date }}</td>
            <td>{{ exam.start_time }}</td>
            <td>{{ exam.end_time }}</td>
            <td>{{ exam.duration }}</td>
            <td>
              <div class="form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  :id="`switch${exam.id}`"
                  :checked="exam.status == 1"
                  @change="changeStatus(exam.id)"
                />
              </div>
            </td>
            <td>
              <i
                class="fa-solid fa-trash"
                style="color: red; font-size: 1rem; margin-left: 4px"
                @click="DeletRow(exam.id)"
                v-if="deletes"
              ></i>

              <i
                style="color: var(--main); font-size: 1rem; margin-left: 4px"
                class="fa-solid fa-pen-to-square"
                @click="EditRow(exam.id)"
                v-if="edit"
              ></i>
              <router-link :to="`/exams-show-group/${exam.id}`">
                <i
                  v-if="read"
                  class="fa-solid fa-eye"
                  style="font-size: 1rem; margin-left: 4px"
                ></i>
              </router-link>

              <router-link :to="`/questions-add-index/${exam.id}`">
                <i
                  class="fa-solid fa-sheet-plastic"
                  title="اضافة سؤال"
                  style="font-size: 1.1rem; margin-left: 4px"
                ></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      role: JSON.parse(localStorage.getItem("user"))?.roles[0]?.modules,
      deletes: false,
      create: false,
      add: false,
      edit: false,
      read: false,
      exams: [],
    };
  },
  methods: {
    async changeStatus(id) {
      try {
        const exam = this.exams.find((e) => e.id === id);
        const newStatus = exam.status == 1 ? 0 : 1;

        const res = await axios.post("toggle_exam_status", {
          id: id,
        });

        if (res.data.status === true) {
          exam.status = newStatus;
          Swal.fire({
            icon: "success",
            title: "تم التحديث بنجاح",
            text: res.data.message || "تم تحديث الحالة بنجاح",
            timer: 500,
            showConfirmButton: false,
          });
          this.fetchExams();
        } else {
          Swal.fire({
            icon: "error",
            title: "فشل التحديث",
            text: res.data.message || "حدث خطاء في عملية التحديث.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "فشل التحديث",
          text: res.data.message || "حدث خطاء في عملية التحديث.",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },

    async fetchExams() {
      try {
        const res = await axios.post("fetch_group_exams", {
          group_id: this.$route.params.groupId,
        });
        if (res.data.status === true) {
          this.exams = res.data.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    EditRow(id) {
      this.$router.push(`/exams-eidt-group/${id}`);
    },

    async DeletRow(id) {
      try {
        const result = await Swal.fire({
          title: "هل أنت متأكد من عملية المسح؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
          cancelButtonText: "إلغاء",
        });

        if (result.isConfirmed) {
          const res = await axios.post("delete_group_exam", { id });

          if (res.data.status === true) {
            await Swal.fire({
              icon: "success",
              title: "تم الحذف بنجاح",
              text: res.data.message,
              timer: 500,
              showConfirmButton: false,
            });
            this.fetchExams();
          } else {
            Swal.fire({
              icon: "error",
              title: "فشل الحذف",
              text: res.data.message || "حدث خطأ أثناء عملية الحذف.",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل الحذف",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  },
  mounted() {
    this.role?.filter((role) => {
      if (role.name == this.$route.meta.module) {
        role.maps?.filter((map) => {
          if (map.name == "create") {
            this.create = true;
            // console.log(this.create);
          }
          if (map.name == "delete") {
            this.deletes = true;
            // console.log(this.delete);
          }
          if (map.name == "update") {
            this.edit = true;
            // console.log(this.edit);
          }
          if (map.name == "read") {
            this.read = true;
            // console.log(this.read);
          }
        });
      }
    });

    this.fetchExams();
  },
};
</script>

<style lang="scss" scoped>
.form-switch {
  margin: auto;
  text-align: center !important;
}
.form-switch .form-check-input {
  margin-left: 0 !important;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-family: "bold";
  }
  button {
    background-color: var(--main);
    color: var(--secondary);
    border-radius: 5px;
    border: 0;
    padding: 0.5rem 1rem;
    font-family: "regular";
    transition: 0.7s;
    border: 1px solid var(--main);
  }
  table.table {
    i.fa-solid.fa-pen-to-square {
      margin-right: 10px;
    }
  }
}
</style>
